import { Component, OnInit } from '@angular/core'

@Component({
    selector: './water-management',
    templateUrl: './water-management.component.html'
})

export class WaterManagementComponent implements OnInit {

    ngOnInit() {

    }
}