export class Utility {
    /**
     * Adds a delay in execution
     * @param ms delay in ms
     */
    static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Copies an object by value instead of reference
     * @param aObject object to be copied
     */
    static copy(aObject) {
        if (!aObject) {
          return aObject;
        }
      
        let v;
        let bObject = Array.isArray(aObject) ? [] : {};
        for (const k in aObject) {
          v = aObject[k];
          bObject[k] = (typeof v === "object") ? this.copy(v) : v;
        }
      
        return bObject;
      }

    /*
      Converts the date stored in the DB to the format of dd-mmm-yyyy hh:mm:ss as requested in GitLab issue #717
    */
    static dateFormat(dbDate){
      let initDate = new Date(Date.parse(dbDate.split(".")[0]));
      //adjust for timzone
      initDate.setMinutes(initDate.getMinutes() - initDate.getTimezoneOffset());
      let removeDayText = initDate.toUTCString().split(",")[1];
      return removeDayText.split("GMT")[0];
    }
}
