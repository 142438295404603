import { Component, OnInit } from '@angular/core'

@Component({
    selector: './view-menu',
    templateUrl: './view-menu.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class ViewMenuComponent implements OnInit {

    ngOnInit() {

    }
}