export class User {
    id: number;
    email: string;
    username: string;
    password: string;
    first_name: string;
    last_name: string;
    role_name: string;
    roleName: string;
    roleId: number;
    requestedSites: string;
    supervisor: string;
    region: string;
    cerberi: any;
    authToken?: string;

    public constructor(init?: Partial<User>) {
        Object.assign(this, init);
    }
}