import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupFormComponent } from './signup-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MessageDivModule } from 'shared-front-end';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [SignupFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MessageDivModule,
    NgxCaptchaModule
  ],
  exports: [
    SignupFormComponent
  ]
})
export class SignupFormModule { }
