import { Component} from '@angular/core';
import { AuthenticationService } from 'shared-front-end';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  private userEmail;

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  logout() {
    this.authenticationService.logout();
  }

  ngOnInit(){
    let user = JSON.parse(localStorage.getItem('currentUser'))
    this.userEmail = user.email;
  }
}
