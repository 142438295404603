import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';
import { Constants } from '../helpers/constants';

@Injectable({ providedIn: 'root' })
export class SiteDetailsService {

    private apiUrl: any;

    constructor(
        private http: HttpClient,
        @Inject('API_URL') apiUrl,
    ) {
        this.apiUrl = apiUrl
    }

    /**
     * Gets a cerberus site's interference
     */
    getSiteInterference(cerbId, interferenceSince?: any, interferenceUntil?: any) {
        let json = {
            cerberus_id: cerbId,
            since: interferenceSince,
            until: interferenceUntil,
            get_viewed: true
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.USER_EVENTS_GET, json)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * Gets a cerberus site's uptime
     */
     getSiteUptime(cerbId, since: any, until: any) {
        let json = {
            cerberus_id: cerbId,
            since: since,
            until: until,
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.CERBERUS_UPTIME, json)
            .pipe(map(data => {
                return data;
            }));
    }

     /**
     * Gets a cerberus site's scans
     */
      getSiteScans(cerbId, since: any, until: any) {
        let json = {
            cerberus_id: cerbId,
            since: since,
            until: until,
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.CERBERUS_SCANS, json)
            .pipe(map(data => {
                return data;
            }));
    }

        /**
     * Gets a cerberus site's interference
     */
    getTraceDetails(cerbId, scan_id, maxWidth, trace_start?: any, trace_end?: any) {
        //TODO: Not sure why I need to convert this. Need to analyze the issue.
        // let offset = new Date().getTimezoneOffset()/60;
        // let conversion = offset*60*60*1000
        // trace_start.setTime(trace_start.getTime() - conversion);
        // trace_end.setTime(trace_end.getTime() - conversion);
        let json = {
            cerb_id: cerbId,
            scan_id: scan_id,
            trace_start: trace_start,
            trace_end: trace_end,
            max_width: maxWidth
        }

        console.log('trace',trace_start,trace_end)

        return this.http.post<any>(this.apiUrl + Constants.Urls.SITE_DETAILS_GET_TRACE_DETAILS, json)
            .pipe(map(data => {
                return data;
            }));
    }

    getDamsNT(cerb_id){
        let json = {
            cerberus_id: cerb_id
        }
        return this.http.post<any>(this.apiUrl + Constants.Urls.DAMSNT_SUMMARY_ACHELOUS, json)
            .pipe(map(data => {
                return data;
            }));
    }

    getAiData(cerbId, scan_id, trace_start?: any, trace_end?: any){
        let json = {
            cerb_id: cerbId,
            scan_id: scan_id,
            trace_start: trace_start,
            trace_end: trace_end,
            return_empties: true
        }
        return this.http.post<any>(this.apiUrl + Constants.Urls.SITE_DETAILS_GET_AI_DETAILS, json)
            .pipe(map(data => {
                return data;
            }));
    }

    getEventPeakLevel(cerb_id, events){
        let json = {
            cerberus_id: cerb_id,
            event_ids: events
        }
        return this.http.post<any>(this.apiUrl + Constants.Urls.EVENT_PEAK_LEVEL, json)
            .pipe(map(data => {
                return data;
            }));
    }

    downloadDamsNT(cerb_id, id, eventId){
        let json = {
            cerberus_id: cerb_id,
            scan_id: id,
            event_id: eventId
        }
        return this.http.post<any>(this.apiUrl + Constants.Urls.DAMSNT_DOWNLOAD_ACHELOUS, json)
            .pipe(map(data => {
                return data;
            }));
    }

    getSetNotes(cerbId, eventId, notes){
        //if setting notes to empty string, add a space to ensure data is erased in backend.
        if(notes != null && notes.length == 0){
            notes = " ";
        }

        let json = {
            cerberus_id: cerbId,
            event_id: eventId,
            notes: notes
        }
        if(notes == null){
            delete json.notes;
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.EVENT_NOTES, json)
            .pipe(map(data => {
                return data;
            }))
    }

    getScanDetails(cerbId, scan_id) {
        let json = {
            cerb_id: cerbId,
            scan_id: scan_id
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.SITE_DETAILS_GET_SCAN_DETAILS, json)
            .pipe(map(data => {
                return data;
            }));
    }

    getRadialData(cerbId, eventId){
        let json = {
            Cerberus_id: cerbId,
            Event_id: eventId
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.SITE_DETAILS_GET_RADIAL_DATA, json)
            .pipe(map(data => {
                return data;
            }));
    }
}