import { Component, OnInit } from '@angular/core'

@Component({
    selector: './iim-pilot-setup',
    templateUrl: './iim-pilot-setup.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class IIMPilotSetupComponent implements OnInit {

    ngOnInit() {

    }
}