import { Component, OnInit } from '@angular/core';

@Component({
    selector: './achelous-hero',
    templateUrl: './achelous-hero.component.html'
  })

export class AchelousHeroComponent implements OnInit {

    ngOnInit() {

    }

}