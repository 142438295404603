import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultModalComponent } from './default-modal.component'

@NgModule({
  declarations: [DefaultModalComponent],
  imports: [
    CommonModule,
  ],
  exports: [DefaultModalComponent]
})
export class DefaultModalModule { }
