import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';
import { Constants } from '../helpers/constants';

@Injectable({ providedIn: 'root' })
export class UserNotificationsService {

    private apiUrl: any;

    constructor(
        private http: HttpClient,
        @Inject('API_URL') apiUrl,
    ) {
        this.apiUrl = apiUrl
    }

    /**
     * Gets a users notifications
     */
    getByUser(userId, notificationsSince?: any, getRead?: any, notificationType?: any) {
        let json = {
            user_id: userId,
            get_read: getRead,
            notification_type: notificationType,
            all_notifications_since: notificationsSince
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.USER_EVENTS_GET, json)
            .pipe(map(data => {
                return data;
            }));
    }

    getBySite(cerberusId, notificationsSince?: any, getRead?: any){
        let json = {
            cerberus_id: cerberusId,
            since: notificationsSince,
            get_viewed: getRead
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.USER_EVENTS_GET, json)
            .pipe(map(data => {
                return data;
            }));
    }

    getByUserAll(userId, cerbId, notificationType?: any) {
        let json = {
            user_id: userId,
            cerb_id: cerbId,
            notification_type: notificationType
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.USER_EVENTS_GET_ALL, json)
            .pipe(map(data => {
                return data;
            }));
    }

    markAsViewed(userId, cerberus_id) {
        let json = {
            cerberus_id: cerberus_id
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.USER_EVENTS_MARK_AS_VIEWED, json)
            .pipe(map(data => {
                console.log('data1:', data)
                return data;
            }));
    }


}