import { Component } from '@angular/core';
import { ITooltipParams } from 'ag-grid-community';
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'tooltip-component',
  template: ` <div class="custom-tooltip" [style.background-color]="color">
    <p>
      <span>{{message}}</span>
    </p>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        width: 100px;
        height: 70px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    `,
  ],
})
export class CustomTooltip implements ITooltipAngularComp {
  private params: { color: string } & ITooltipParams;
  private severity: any;
  private color: string;
  private message: string;

  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;
    this.severity = params.api.getDisplayedRowAtIndex(params.rowIndex).data.severity;
    if(this.severity == '1'){
        this.message = "Low Severity";
    }else{
        this.message = "High Severity"
    }
    this.color = '#d7d9db';
  }
}
