import { Component, OnInit } from '@angular/core'

@Component({
    selector: './ethernet-fiber-system',
    templateUrl: './ethernet-fiber-system.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class EthernetFiberSystemComponent implements OnInit {

    ngOnInit() {

    }
}