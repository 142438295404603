import { Component, OnInit } from '@angular/core'

@Component({
    selector: './objective',
    templateUrl: './objective.component.html'
})

export class ObjectiveComponent implements OnInit {

    ngOnInit() {

    }
}