import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Topic } from './topic';
import { TOPICS } from './topics';
import { HelpMessageService } from '../../help-message.service';

@Injectable({
    providedIn: 'root',
})
export class TopicService {
    constructor( private helpMessageService: HelpMessageService) { }

    getTopics(): Observable<Topic[]> {
        this.helpMessageService.add('TopicService: fetched topics');
        console.log(TOPICS)
        return of (TOPICS);
    }

    getTopic(id: number | string) {
        return this.getTopics().pipe(
            map((topics: Topic[]) => topics.find(topic => topic.id === + id)!)
        );
    }
}