import { Component, OnInit } from '@angular/core'

@Component({
    selector: './move-copy-swap',
    templateUrl: './move-copy-swap.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class MoveCopySwapComponent implements OnInit {

    ngOnInit() {

    }
}