import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Constants } from 'shared-front-end'
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CerberiService {

    private apiUrl: any

    constructor(
        @Inject("API_URL") apiUrl,
        private http: HttpClient
    ) {
        this.apiUrl = apiUrl
    }

    getAllSites() {
        let json = {}
        console.log(environment.apiUrl)
        return this.http.post<any>(this.apiUrl+Constants.Urls.GET_SITES, json)
            .pipe(map(cerberi => {
                return cerberi;
            }));
    }

    // expecting cerberi.id as opposed to cerberi.site_id or site.id
    getSite(cerb_id: number){
        let json = {
            'cerb_id': cerb_id 
        }

        return this.http.post<any>(this.apiUrl+Constants.Urls.GET_SITES, json)
        .pipe(map(cerberi => {
            return cerberi;
        }));
    }

    // this in the only one being used at this point in development
    getUserSites(user_id: number, user_role: string, interference_since?: any){
        let json = {
        }

        return this.http.post<any>(this.apiUrl+Constants.Urls.GET_SITES, json)
        .pipe(map(cerberi => {
            return cerberi;
        }));
    }

    getDiskSpace(){
        return this.http.post<any>(this.apiUrl + Constants.Urls.DISK_SPACE_GET, {})
            .pipe(map(data => {
                return data;
            }));
    }
}