import { animate, style, transition, trigger, state } from '@angular/animations';
import { Constants } from '../helpers/constants'

// Smoothes height changes
export const smoothHeight = trigger('grow', [
  transition('void <=> *', []),
  transition('* <=> *', [style({ height: '{{startHeight}}px'}), animate('.3s ease')], {
    params: { startHeight: 0 }
  })
]);

// Slides an element
export const slideAnimation = trigger('slide', [
  state(Constants.LoginPane.LEFT, style({ transform: 'translateX(0)' })),
  state(Constants.LoginPane.CENTER, style({ transform: 'translateX(-33.333%)' })),
  state(Constants.LoginPane.RIGHT, style({ transform: 'translateX(-66.667%)' })),
  transition('* => *', animate(300))
]);

// Slides an element
export const configSlideAnimation = trigger('slide', [
  state(Constants.LoginPane.LEFT, style({ transform: 'translateX(0)' })),
  state(Constants.LoginPane.RIGHT, style({ transform: 'translateX(-100%)' })),
  transition('* => *', animate(300))
]);