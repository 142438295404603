import { Component, OnInit } from '@angular/core'

@Component({
    selector: './cage-system-setup',
    templateUrl: './cage-system-setup.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class CageSystemSetupComponent implements OnInit {

    ngOnInit() {

    }
}