import { Component, OnInit } from '@angular/core'

@Component({
    selector: './cleaning-maintenance',
    templateUrl: './cleaning-maintenance.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class CleaningMaintenanceComponent implements OnInit {

    ngOnInit() {

    }
}