import { Component, OnInit } from '@angular/core'

@Component({
    selector: './drgs-site-details',
    templateUrl: './drgs-site-details.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class DRGSSiteDetailsComponent implements OnInit {

    ngOnInit() {

    }
}