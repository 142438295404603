import { UserService } from './user.service';
import { of } from 'rxjs';

export class MockUserService extends UserService {

    /**
     * Mocks user registration
     * @param user 
     */
    registerUser(user: any) {
        return of(1)
    }

    /**
     * Mocks getting all users from DB
     */
    getAll() {
        let users = [{
            disabled: false,
            email: "fred@slateRock.com",
            firstName: "fred",
            id: 1,
            lastName: "test",
            roleName: "Administrator"
        },
        {
            disabled: false,
            email: "barney@slateRock.com",
            firstName: "barney",
            id: 2,
            lastName: "test",
            roleName: "Pending"

        }];
        return of(users);
    }

    /**
     * Returns array of users instead of observable
     */
    getAllArray(){
        return [{
            disabled: false,
            email: "fred@slateRock.com",
            firstName: "fred",
            id: 1,
            lastName: "test",
            roleName: "Administrator"
        },
        {
            disabled: false,
            email: "barney@slateRock.com",
            firstName: "barney",
            id: 2,
            lastName: "test",
            roleName: "Pending"

        }];
    }
}