import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlotlyModule } from 'angular-plotly.js';
import { SiteDashboardComponent } from './site-dashboard.component';
import { CoreModule } from 'src/app/core/core.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [SiteDashboardComponent],
  imports: [
    CommonModule,
    PlotlyModule,
    CoreModule,
    AppRoutingModule,
    FontAwesomeModule
  ]
})
export class SiteDashboardModule { }
