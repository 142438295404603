import { Injectable, NgModule } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if (currentUser && currentUser['authToken']) {
            let headers = new HttpHeaders({
                'authorization': `${currentUser['authToken']}`,
                'email': `${currentUser['email']}`
            })
            //append auth info to request body
            let auth = {
                'auth': {
                    'token': `${currentUser['authToken']}`,
                    'email': `${currentUser['email']}`
                }
            }
            let newBody = Object.assign(request.body, auth);
            request = request.clone({ headers });
            request = request.clone({ body: newBody });
        }

        return next.handle(request);
    }
}