import { Component, OnInit } from '@angular/core'

@Component({
    selector: './prime-operations',
    templateUrl: './prime-operations.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class PrimeOperationsComponent implements OnInit {

    ngOnInit() {

    }
}