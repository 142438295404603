export class Message {
    type: string;
    text: string;

    setMessage(type:string, text:string){
        this.type = type;
        this.text = text;
    }
}

