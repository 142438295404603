import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteLocationMapComponent } from './site-location-map.component';
import { PlotlyModule } from 'angular-plotly.js';
import { CoreModule } from '../../core/core.module';
import { AgGridModule } from 'ag-grid-angular';
import { AnimationsModule } from 'shared-front-end';

@NgModule({
  declarations: [SiteLocationMapComponent],
  imports: [
    CommonModule,
    PlotlyModule,
    CoreModule,
    AnimationsModule,
    AgGridModule.withComponents([])
  ]
})
export class SiteLocationMapModule { }
