import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Constants } from '../helpers/constants';

@Injectable({ providedIn: 'root' })
export class UserService {

    private apiUrl: any;

    constructor(
        private http: HttpClient,
        @Inject('API_URL') apiUrl,
    ) {
        this.apiUrl = apiUrl
    }

    /**
     * Submits user registration form to API
     * @param user Requires email, password, first, last
     */
    registerUser(user: any) {
        return this.http.post<any>(this.apiUrl + Constants.Urls.REGISTER, <JSON>user)
            .pipe(map(user => {
                return user;
            }));
    }

    createUser(user: any) {
        return this.http.post<any>(this.apiUrl + Constants.Urls.CREATE, <JSON>user)
            .pipe(map(user => {
                return user;
            }));
    }

    /**
     * Submits recover password form to API
     * @param email 
     */
    recoverPassword(email: string) {
        let jsonInfo = {
            "email": email
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.RECOVER, jsonInfo)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * Gets all users
     */
    getAll() {
        return this.http.post<any>(this.apiUrl + Constants.Urls.GET_USERS, {}).pipe(map(data => {
            return data;
            }));
    }
    
    /**
     * Gets all users
     */
    get(id) {
        let json = {
            "id": id
        }
        return this.http.post<any>(this.apiUrl + Constants.Urls.GET_USERS, json).pipe(map(data => {
            return data;
        }));
    }

    /**
     * Edits a user
     * @param user user to be edited
     */
    editUser(user: any) {
        let json = {
            email: user.email,
            firstName: user.firstName,
            id: user.id,
            lastName: user.lastName,
            roleName: user.roleName,
            password: user.password,
            cerberi: user.cerberi,
            supervisor: user.supervisor,
            region: user.region,
            requestedSites: user.requestedSites
        }

        //If password length is 0 (empty string), remove it from the payload.
        if(user.password.length <1){
            delete json.password
        }
        return this.http.post<any>(this.apiUrl + Constants.Urls.EDIT_USER, json)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * Edits a user email prefs
     * @param json user to be edited
     */
     getUserPrefs(json) {
        return this.http.post<any>(this.apiUrl + Constants.Urls.GET_USER_PREFS, json).pipe(map(data => {
            return data;
            }));
    }

    /**
     * Edits a user email prefs
     * @param json user to be edited
     */
     editUserPrefs(json) {
        return this.http.post<any>(this.apiUrl + Constants.Urls.EDIT_USER_PREFS, json)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * Confirms a user account creation
     * @param tokenStr token sent in email to validate the user
     */
    confirmUser(tokenStr) {
        let jsonInfo = {
            "token": `${tokenStr}`
        }

        return this.http.post<any>(this.apiUrl + Constants.Urls.CONFIRM_USER, jsonInfo)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * Deletes the specified user
     * @param json user to be deleted
     */
     deleteUser(json) {
        return this.http.post<any>(this.apiUrl + Constants.Urls.DELETE, json)
            .pipe(map(data => {
                return data;
            }));
    }

    getConfig(){
        return this.http.post<any>(this.apiUrl + Constants.Urls.CONFIG_GET, {})
            .pipe(map(data => {
                return data;
            }));
    }
    
    setConfig(json){
        return this.http.post<any>(this.apiUrl + Constants.Urls.CONFIG_SET, json)
            .pipe(map(data => {
                return data;
            }));
    }

    removeOldData(date){
        let json = {
            older_than: date
        }
        return this.http.post<any>(this.apiUrl + Constants.Urls.CONFIG_REMOVE_DATA, json)
            .pipe(map(data => {
                return data;
            }));
    }
}