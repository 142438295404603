import { Component } from '@angular/core';
import { smoothHeight, slideAnimation, Message, Constants } from 'shared-front-end';
import { ActivatedRoute } from '@angular/router';
import { UserService, SocketService } from 'shared-front-end'
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [smoothHeight, slideAnimation]
})
export class LoginComponent {
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) { }

  // Displays form messages
  message = new Message();
  token = this.route.snapshot.queryParamMap.get('token');
  // Controls what will eventually be displayed after animation completes
  visiblePane = (this.token)?Constants.LoginPane.RIGHT:Constants.LoginPane.LEFT;
  // Controls what is currently displayed
  visiblePaneDisplay = (this.token)?Constants.LoginPane.RIGHT:Constants.LoginPane.LEFT;

  // Controls what to display in the right pane
  displaySignup = false;

  ngAfterViewInit(): void {
    if (this.token){
      this.message.setMessage(Constants.MessageType.PRIMARY, Constants.UiMessages.ATTEMPTING_CONFIRM_USER);
      this.userService.confirmUser(this.token)
      .pipe(first())
      .subscribe(
        data => {
          this.message.setMessage(Constants.MessageType.SUCCESS, Constants.UiMessages.CONFIRM_SUCCESS);
        },
        error => {
          this.message.setMessage(Constants.MessageType.ERROR, Constants.UiMessages.INVALID_CONFIRM_TOKEN);
        });     
    }
  }

  /**
   * Displays the results page, and sets message to success
   */
  onRegistration() {
    this.onDisplayResultMessage();
    this.message.setMessage(Constants.MessageType.SUCCESS, Constants.UiMessages.ACHELOUS_REGISTER_SUCCESS);
  }

  /**
   * Displays results page, sets message
   */
  onRecovery(){
    this.onDisplayResultMessage();
    this.message.setMessage(Constants.MessageType.SUCCESS, Constants.UiMessages.RECOVER_SUCCESS);
  }

  /**
   * Sets the page to display the left pane (login form)
   */
  onDisplayLogin() {
    this.visiblePane = Constants.LoginPane.LEFT;
  }

  /**
 * Displays the forgot password pane
 */
  onDisplayForgotPassword() {
    this.visiblePane = Constants.LoginPane.CENTER;
    this.displaySignup = false;
  }

  /**
   * Sets the page to display the center pane (signup form)
   */
  onDisplaySignup() {
    this.visiblePane = Constants.LoginPane.CENTER;
    this.displaySignup = true;
  }

  /**
* Sets the page to display the right pane (results pane)
*/
  onDisplayResultMessage() {
    this.visiblePane = Constants.LoginPane.RIGHT;
  }

  /**
   * This is fired when the slide animation completes and updates the visible pane variables
   */
  slideEndingEvent() {
    this.visiblePaneDisplay = this.visiblePane;
  }
}
