import { Component, OnInit } from '@angular/core'

@Component({
    selector: './goesdcs-interference-concerns',
    templateUrl: './goesdcs-interference-concerns.component.html'
})

export class GoesDCSInterferenceConcernsComponent implements OnInit {

    ngOnInit() {

    }
}