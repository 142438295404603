import { Component, OnInit } from '@angular/core'

@Component({
    selector: './alarm-codes',
    templateUrl: './alarm-codes.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class AlarmCodesComponent implements OnInit {

    ngOnInit() {

    }
}