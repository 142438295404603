import { Component, OnInit } from '@angular/core'

@Component({
    selector: './data-acquisition-monitoring',
    templateUrl: './data-acquisition-monitoring.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class DataAcquisitionMonitoringComponent implements OnInit {

    ngOnInit() {

    }
}