import { Topic } from "./topic";

export const TOPICS: Topic[] = 
[
    { 
     id: "introduction", 
     name: "Introduction", 
     children: [
            {
                id: "objective",
                name: "Objective",
                component: "DamsntHeroComponent"
            },
            {
                id: "water-management",
                name: "USACE Water Management",
                component: "DamsntHeroComponent"
            },
            {
                id: "drgs",
                name: "Direct Readout Ground Station",
                component: "DamsntHeroComponent"
            },
            {
                id: "interference-concerns",
                name: "Interference Concerns",
                component: "DamsntHeroComponent"
            }
        ]
    },
    { 
     id: "damsnt-drgs", 
     name: "DAMS-NT DRGS", 
     children: [
        {
            id: "damsnt-overview",
            name: "System Overview",
            component: "DamsntHeroComponent"
        },
        {
            id: "parabolic-dish",
            name: "Parabolic Dish",
            component: "DamsntHeroComponent"
        },
        {
            id: "antenna-controller",
            name: "Antenna Controller",
            component: "DamsntHeroComponent"
        },
        {
            id: "ethernet-fiber-system",
            name: "Ethernet Over Fiber System",
            component: "DamsntHeroComponent"
        },
        {
            id: "rf-fiber",
            name: "RF Over Fiber System",
            component: "DamsntHeroComponent"
        },
        {
            id: "cleaning-maintenance",
            name: "Cleaning and Maintenance",
            component: "DamsntHeroComponent"
        },
        {
            id: "drgs-equipment",
            name: "DRGS Equipment",
            component: "DamsntHeroComponent"
        },
    ]
    },
    { 
        id: "damsnt-software", 
        name: "DAMS-NT Software", 
        children: [
            {
                id: "data-acquisition-monitoring",
                name: "DCS Data Acquisition and Monitoring System",
                component: "DamsntHeroComponent"
            },
            {
                id: "slot-status",
                name: "Slot Status Page",
                component: "DamsntHeroComponent"
            },
            {
                id: "cage-status",
                name: "Cage Status Page",
                component: "DamsntHeroComponent"
            },
            {
                id: "socket-status",
                name: "Socket Status Page",
                component: "DamsntHeroComponent"
            },
            {
                id: "setup-menu-preferences",
                name: "Setup Menu and Preferences",
                component: "DamsntHeroComponent"
            },
            {
                id: "iim-pilot-setup",
                name: "IIM Pilot Setup",
                component: "DamsntHeroComponent"
            },
            {
                id: "cage-system-setup",
                name: "Cage/System Setup",
                component: "DamsntHeroComponent"
            },
            {
                id: "prime-operations",
                name: "Prime Operations",
                component: "DamsntHeroComponent"
            },
            {
                id: "move-copy-swap",
                name: "Move/Copy/Swap",
                component: "DamsntHeroComponent"
            },
            {
                id: "auto-fail-over",
                name: "Auto-Fail Over",
                component: "DamsntHeroComponent"
            },
            {
                id: "view-menu",
                name: "View Menu",
                component: "DamsntHeroComponent"
            },
            {
                id: "window-menu",
                name: "Window Menu",
                component: "DamsntHeroComponent"
            },
            {
                id: "slot-menu",
                name: "Slot Menu",
                component: "DamsntHeroComponent"
            }
        ]
    },
    { 
        id: "troubleshooting", 
        name: "Troubleshooting & Alarm Codes", 
        children: [
            {
                id: "damsnt-faq",
                name: "DAMS-NT FAQ",
                component: "DamsntHeroComponent"
            },
            {
                id: "drgs-satelite-faq",
                name: "DRGS Satellite Dish FAQ",
                component: "DamsntHeroComponent"
            },
            {
                id: "antenna-controller-faq",
                name: "Antenna Controller FAQ",
                component: "DamsntHeroComponent"
            },

            {
                id: "spectrum-monitoring-faq",
                name: "Spectrum Monitoring Software FAQ",
                component: "DamsntHeroComponent"
            },
            {
                id: "alarm-codes",
                name: "Antenna Controller Alarm Codes",
                component: "DamsntHeroComponent"
            }
        ]
    },
    { 
        id: "achelous", 
        name: "Achelous", 
        children: [
            {
                id: "achelous-description",
                name: "Description",
                component: "DamsntHeroComponent"
            },
            {
                id: "achelous-overview",
                name: "Overview",
                component: "DamsntHeroComponent"
            },
            {
                id: "drgs-site-details",
                name: "DRGS Site Details",
                component: "DamsntHeroComponent"
            },
            {
                id: "achelous-users",
                name: "Users",
                component: "DamsntHeroComponent"
            },
            {
                id: "achelous-reports",
                name: "Reports",
                component: "DamsntHeroComponent"
            }
        ]
    },
    {
        id: "specifications-warranties",
        name: "Specifications & Warranties",
        children: [
            {
                id: "specifications",
                name: "Antenna Controller Specifications",
                component: "DamsntHeroComponent"
            },
            {
                id: "warranties",
                name: "Antenna Controller Warranty",
                component: "DamsntHeroComponent"
            },
        ]
    },
    { 
        id: "videos", 
        name: "Videos", 
        children: [
            {
                id: "drgs-videos",
                name: "DRGS",
                component: "DamsntHeroComponent"
            }/*,
            {
                id: "damsnt-videos",
                name: "DAMS-NT",
                component: "DamsntHeroComponent"
            },
            {
                id: "achelous-videos",
                name: "Achelous",
                component: "DamsntHeroComponent"
            }*/
        ]
    },
]