import { Component, OnInit } from '@angular/core'

@Component({
    selector: './drgs-satelite-faq',
    templateUrl: './drgs-satelite-faq.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class DRGSSateliteFAQComponent implements OnInit {

    ngOnInit() {

    }
}