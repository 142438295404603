export class Constants{

    /**
     * Stores any UI messages displayed to the user
     */
    public static readonly UiMessages = {
        AUTHENTICATION_FAILURE: "Incorrect username or password.",
        EMAIL_IN_USE: "An error occurred. Please try again. If issues persist, contact a system administrator.",
        CERBERUS_REGISTER_SUCCESS: "You have successfully created an account.\n\n This account is immediately available for use on Cerberus and you may login now.\n\n\
        Your account will be available for use on Achelous in the next 24 hours. You will receive an email letting you know that the account is ready.",
        ACHELOUS_REGISTER_SUCCESS: "You have successfully registered for an account. Please confirm your email by clicking the link in the email sent to you.",
        RECOVER_SUCCESS: "A message has been sent to the provided email with instructions on resetting your password.",
        CONFIRM_SUCCESS: "Your email has been confirmed. An email has been sent to all admin users notifying them of your pending account. Once an admin approves your account you will receive an email letting you know that you can login.",
        INVALID_CONFIRM_TOKEN: "This link is invalid, please contact support for help resolving this issue.",
        ATTEMPTING_CONFIRM_USER: "We are attempting to confirm your account, please stand by."
    };

    /**
     * Stores the type of UI message
     */
    public static readonly MessageType = {
        ERROR: "error",
        SUCCESS: "success",
        PRIMARY: "primary"
    }

    /**
     * Stores API response status and values
    */ 
    public static readonly ApiResponse = {
        // Status
        ERROR: "error",
        OK: "ok",

        // Values
        DUPLICATE: "duplicate"
    };

    /**
     * Stores values to control display of login page panes
     */
    public static readonly LoginPane = {
        LEFT: "left",
        RIGHT: "right",
        CENTER: "center"
    };

    /**
     * Stores Urls
     */
    public static readonly Urls = {
        LOGIN: "/login",

        GET_SITES: "/cerberi/get",
        
        GET_USERS: "/user/get",
        RECOVER: "/user/recover",
        REGISTER: "/user/register",
        GET_ROLES: "/user_roles/get",
        EDIT_USER: "/user/edit",
        GET_USER_PREFS: "/user/getprefs",
        EDIT_USER_PREFS: "/user/editprefs",
        CONFIRM_USER: "/user/confirm",
        CREATE: "/user/create",
        DELETE: "/user/disable",

        GET_ACTIVE_SCAN: "/scan/getActive",
        ADD_SCAN: "/scan/add",
        GET_SCAN: "/scan/get",
       
        SCAN_START: "/scan/start",
        SCAN_CONNECT: "/scan/connect",
        SCAN_DATA: "/scan/data",
        SCAN_STOP: "/scan/stop",
        SCAN_RECENT: "/scan/recent",
        SCAN_ERRORS: "/scan/scan_errors",

        DAMSNT_SUMMARY: "/damsnt/summary",
        DAMSNT_DOWNLOAD: "/damsnt/getDamsntCSV",
        DAMSNT_DOWNLOAD_ACHELOUS: "/cerberi/getDamsntCSV",
        DAMSNT_SUMMARY_ACHELOUS: "/cerberi/events",
        EVENT_NOTES: "/cerberi/event/notes",
        EVENT_PEAK_LEVEL: "/cerberi/events_peak_lvl",
        CERBERUS_UPTIME: "/cerberi/uptime",
        CERBERUS_SCANS: "/cerberi/scans",

        GET_SENSORS: "/sensor/get",
        GET_SENSORS_DEFAULT_VALUES: "/sensor/getSensorDefaults",
        GET_SENSOR_STATUS: "/sensor/status",
        GET_SENSOR_SETTINGS: "/sensor/getSensorSettings",
        SET_SENSOR_SETTINGS: "/sensor/setSensorSettings",
        GET_SENSOR_TRANSLATION: "/sensor/getSensorTranslation",
        GET_SENSOR_READINESS: "/sensor/ready",

        USER_EVENTS_GET: "/user_events/get",
	    	USER_EVENTS_GET_ALL: "/user_events/getAll",
		    USER_EVENTS_MARK_AS_VIEWED: "/user_events/mark_as_viewed",

        CONFIG_GET: "/config/get",
        CONFIG_SET: "/config/set",
        CONFIG_REMOVE_DATA: "/config/remove_old_data",

        DASHBOARD_GET: "/dashboard/get",
		
        SITE_DETAILS_GET_SITE_INTERFERENCE_EVENTS: "/site_details/getSiteInterferenceEvents",
		    SITE_DETAILS_GET_TRACE_DETAILS: "/site_details/getTraceDetails",
		    SITE_DETAILS_GET_AI_DETAILS: "/site_details/getAIDetails",
        SITE_DETAILS_GET_SCAN_DETAILS: "/site_details/getScanDetails",
        SITE_DETAILS_GET_RADIAL_DATA: "/cerberi/event_eb500_radial_data",
        
        DISK_SPACE_GET: "/system/disk",
        
    };

    public static Logout = {
        MINUTES_UNITL_AUTO_LOGOUT: 30, // in mins
        CHECK_INTERVAL: 1000, // in ms
        STORE_KEY: 'lastAction'
    }

    public static SitesMap = {
        id: "sites-map",
        style: {
          height: "100%"
        },
        config: {
          mapboxAccessToken: 'pk.eyJ1IjoibWNmYWhsZXIiLCJhIjoiY2pzeG9ncjd4MDZycjQzcWo0YWxrYWVjNyJ9.WU-VKSxOq9OKl0p-Esn-Tw',
          displayModeBar: false,
          responsive: true,
        },
        data: [{
          type: 'scattermapbox',
          mode: 'markers',
          text: [],
          hoverinfo: [],
          lat: [],
          lon: [],
          marker: {
            color: 'rgba(20, 167, 108,0.6)',
            size: 24,
          },
          name: 'No Interference',
        },
        {
          type: 'scattermapbox',
          mode: 'markers',
          text: [],
          hoverinfo: [],
          lat: [],
          lon: [],
          marker: {
            color: 'rgba(249, 220, 31, 0.6)',
            size: 24,
          },
          name: 'Interference Warning',
        },
        {
          type: 'scattermapbox',
          mode: 'markers',
          text: [],
          hoverinfo: [],
          lat: [],
          lon: [],
          marker: {
            color: 'rgba(230, 39, 57,0.6)',
            size: 24,
          },
          name: 'Interference',
        },
        {
          type: 'scattermapbox',
          mode: 'markers',
          text: [],
          hoverinfo: [],
          lat: [],
          lon: [],
          marker: {
            color: 'rgba(128, 128, 128,0.6)',
            size: 24,
          },
          name: '',
        },
        ],
        layout: {
          showlegend: false,
          autosize: true,
          font: {
            color: 'black'
          },
          dragmode: 'zoom',
          margin: {
            r: 0,
            t: 0,
            b: 0,
            l: 0,
            pad: 0
          },
          mapbox: {
            center: {
              lat: 38.03697222,
              lon: -95.70916722
            },
            domain: {
              x: [0, 1],
              y: [0, 1]
            },
            style: 'light',
            zoom: 4.25
          },
        },
      };

    public static ALERT_TIME = 60;

    public static DashboardMaps = {
        id: "custom-map",
        style: {
          height: "100%"
        },
        config: {
          mapboxAccessToken: 'pk.eyJ1IjoibWNmYWhsZXIiLCJhIjoiY2pzeG9ncjd4MDZycjQzcWo0YWxrYWVjNyJ9.WU-VKSxOq9OKl0p-Esn-Tw',
          displayModeBar: false,
          responsive: true,
        },
        data: [{
          type: 'scattermapbox',
          mode: 'markers',
          text: [],
          hoverinfo: "name",
          lat: [],
          lon: [],
          marker: {
            color: 'rgba(20, 167, 108,0.6)',
            size: 24,
          },
          name: 'No Interference',
        },
        {
          type: 'scattermapbox',
          mode: 'markers',
          text: [],
          hoverinfo: "name",
          lat: [],
          lon: [],
          marker: {
            color: 'rgba(249, 220, 31, 0.6)',
            size: 24,
          },
          name: 'Interference Warning',
        },
        {
          type: 'scattermapbox',
          mode: 'markers',
          text: [],
          hoverinfo: "name",
          lat: [],
          lon: [],
          marker: {
            color: 'rgba(230, 39, 57,0.6)',
            size: 24,
          },
          name: 'Interference',
        },
        {
          type: 'scattermapbox',
          mode: 'markers',
          text: [],
          hoverinfo: "name",
          lat: [],
          lon: [],
          marker: {
            color: 'rgba(128, 128, 128,0.6)',
            size: 24,
          },
          name: 'Unkown',
        },
        ],
        layout: {
          showlegend: false,
          autosize: true,
          font: {
            color: 'black'
          },
          dragmode: 'zoom',
          margin: {
            r: 0,
            t: 0,
            b: 0,
            l: 0,
            pad: 0
          },
          mapbox: {
            center: {
              lat: 38.03697222,
              lon: -95.70916722
            },
            domain: {
              x: [0, 1],
              y: [0, 1]
            },
            style: 'light',
            zoom: 12
          },
        },
      };
}