import { Component, ViewChild, TemplateRef, OnInit, AfterViewInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Constants, TemplateRendererComponent, UserService, UserRolesService, Utility, FormHelpers, User, SocketService } from 'shared-front-end';
import { CerberiService } from '../../core/services/cerberi.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GridOptions } from 'ag-grid-community';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as alertify from 'alertifyjs';
import { ActivatedRoute } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
  animations: []
})

export class UserManagementComponent implements OnInit {
  /* Sets template reference for the edit cell in the users table */
  @ViewChild('editCell') editCell: TemplateRef<any>;
  @ViewChild('alertCell') alertCell: TemplateRef<any>;
  @ViewChild('deleteCell') deleteCell: TemplateRef<any>;
  @ViewChild('cerberiList') cerberiList;
  @ViewChild('cerberiMuteList') cerberiMuteList;
  modalConfig = { buttonOneText: 'OK', escapeToClose: true, headingText: "Email Preferences", contentText: "&ltform [formGroup]=\"emailPrefsForm\"&gt&ltdiv class=\"row\"&gt&ltdiv class=\"col-12\"&gt&ltdiv class=\"form-group floating-label\"&gt&ltlabel for=\"email\"&gtHours between receiving alerts&lt/label&gt&ltinput formControlName=\"hours\" type=\"number\" class=\"form-control\" id=\"hours\" aria-describedby=\"hours\"placeholder=\"Enter hours\"&gt&lt/div&gt&lt/div&gt&lt/div&gt&lt/form&gt"}

  visiblePane = 'left';

  userFormSubmitted: boolean = false;
  userNotNew: boolean = false;

  editUserForm: FormGroup;
  currentUser: any;
  userData: any;
  userPrefs: any;
  loading: boolean = false;
  userRoles: any;
  userRolesBackup: any;
  editUserSelected = false;
  bsModalRef: BsModalRef;
  loggedInUserRole: any;
  isAdmin = false;
  isSuper = false;

  /* Multiselect settings */
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };

  dropdownList: any
  muteDropdownList: any

  /* Set initial grid options */
  gridOptions: GridOptions = {
    //use the user id's assigned by the db instead of 0,1,2...
    getRowNodeId: (data) => data.id,
    suppressCellSelection: true,
    rowHeight: 51,
    headerHeight: 51
  }

  constructor(
    private userService: UserService,
    private userRolesService: UserRolesService,
    private socketService: SocketService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cerberiService: CerberiService,
    private modalService: BsModalService
  ) {

    /* Form validators */
    this.editUserForm = this.formBuilder.group({
      email: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.minLength(16)]],
      roleName: ['', Validators.required],
      supervisor: [''],
      region: [''],
      requestedSites: [''],
      cerberi: [''],
      id: [''],
      interval:[''],
      muteList:['']
    })

    this.setAlertifyDefaults()
  }

  ngOnInit(): void {

    const initialUser = this.route.snapshot.queryParamMap.get('approveUser');

    let user = JSON.parse(localStorage.getItem('currentUser'))
    this.loggedInUserRole = user['roleName']
    if(this.loggedInUserRole == "Administrator"){
      this.isAdmin = true;
    }else{
      this.isAdmin = false;
    }
    if(this.loggedInUserRole == "Super User"){
      this.isSuper = true;
    }else{
      this.isSuper = false;
    }

    // Handles a user sync event via websocket
    // this.socketService.onReceiveMessage('userSync').subscribe(userData => {
    //   console.log('usersync')
    //   let user = new User(JSON.parse(userData))
    //   user.roleName = this.userRolesService.getRoleName(user.roleId)
    //   this.addUserRow(user)
    // })

    // Get user data and init page
    this.userService.getAll()
      .pipe(first())
      .subscribe(
        data => {
          this.userData = data;
          
          this.populateGrid(data);
          /* ensures no elments are cut off by window size */
          if(window.innerWidth > 1000) {
            this.gridOptions.api.sizeColumnsToFit();
          }
          if (initialUser) {
            let user = this.getUserById(data, initialUser)
            if (user) {
              this.editUser(user)
            }
          }
        },
        error => {          
          let msg = alertify.error("Error getting users");
          msg.delay(Constants.ALERT_TIME);
        });

        // Get cerberi data and init multiselect
        this.userRolesService.getAll().pipe(first()).subscribe(data =>{
          this.userRoles = [];
          this.userRolesBackup = [];
          for (let i = 0; i < data.user_roles.length; i++) {
            this.userRoles.push(data.user_roles[i]);
            this.userRolesBackup.push(data.user_roles[i]);
          }
        },
        error => {          
          let msg = alertify.error("Error getting user roles.");
          msg.delay(Constants.ALERT_TIME);
          })

    // Get cerberi data and init multiselect
        this.cerberiService.getAllSites().pipe(first()).subscribe(data =>{
          this.populateCerberi(data);
        },
        error => {          
          let msg = alertify.error("Error getting cerberi sites.");
          msg.delay(Constants.ALERT_TIME);
          })

  }
  //*********************
  // MULTISELECT FUNCTIONALITY 
  //********************* 
  populateCerberi(data) {
    this.dropdownList = data.cerberi;
  }

  onCerberiClick(){
    if(this.editUserFormFields.roleName.value == "Super User"   || this.editUserFormFields.roleName.value == "Administrator"){
      this.editUserFormFields.cerberi.disable();
    }else{
      this.editUserFormFields.cerberi.enable();
    }
  }

  //*********************
  // GRID FUNCTIONALITY 
  //********************* 

  /**
   * Sets the columns of the grid to fill width
   */
  onGridReady(grid: any) {
    /* ensures table doesn't truncate user information by having a single table width below 1000px window width */
    if(window.innerWidth > 1000) {
      grid.api.sizeColumnsToFit();
    }
    /* creates horizontal scroll bar when all elements cannot be seen due to adjusting the screen size*/
    grid.api.ensureColumnVisible();
    this.gridOptions.getRowNodeId = function (data) {
      return data.id;
    };
  }

  openModalWithComponent(user) {
    let json = {
      email: user.email
    }
    this.userService.getUserPrefs(json).pipe(first())
      .subscribe(
        data => {
          this.muteDropdownList = [];
          //create the dropdown list array
          for(let i=0; i<Object.keys(data.cerberi).length; i++){
            let key = Object.keys(data.cerberi)[i];
            this.muteDropdownList.push({
              id: key,
              site_id: key,
              name: data.cerberi[key].cerb_name
            })
          }
          this.muteDropdownList.slice(0);
          const initialState = {
            user: user,
            data: data,
            checkUserCerberiPref: this.checkUserCerberiPref,
            editFunction: this.editUserPrefs,
            hideFunction: this.hideModal,
            muteDropdownList: this.muteDropdownList,
            title: 'Alert Preferences for Email Notifications'
          };
          this.bsModalRef = this.modalService.show(ModalContentComponent, {initialState});
          this.bsModalRef.content.closeBtnName = 'Save';        
        },
        error => {          
          let msg = alertify.error("Error getting user alert preferences.");
          msg.delay(Constants.ALERT_TIME);
        });
    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  hideModal(){
    this.bsModalRef.hide();
  }

  /**
  * Gets user data for grid and specifies column defs 
  */
  populateGrid(data) {
    this.setGridRowData(data.users);

    //if User is an admin, give Delete column. 
    if(this.isAdmin || this.isSuper){
      this.gridOptions.api.setColumnDefs([
        { headerName: 'Email', field: 'email', filter: true, enableCellChangeFlash: true },
        { headerName: 'First Name', field: 'first_name', filter: true, enableCellChangeFlash: true },
        { headerName: 'Last Name', field: 'last_name', filter: true, enableCellChangeFlash: true },
        { headerName: 'Role', field: 'role_name', filter: true, enableCellChangeFlash: true },
        {
          headerName: 'Edit',
          colId: 'edit',
          cellRendererFramework: TemplateRendererComponent,
          cellRendererParams: {
            ngTemplate: this.editCell
          },
          cellClass: 'no-border',
        },
        {
          headerName: 'Alert Preferences',
          colId: 'alert',
          cellRendererFramework: TemplateRendererComponent,
          cellRendererParams: {
            ngTemplate: this.alertCell
          },
          cellClass: 'no-border',
        },
        {
          headerName: 'Delete',
          colId: 'delete',
          cellRendererFramework: TemplateRendererComponent,
          cellRendererParams: {
            ngTemplate: this.deleteCell
          },
          cellClass: 'no-border',
        }
      ]);
    }else{
      this.gridOptions.api.setColumnDefs([
        { headerName: 'Email', field: 'email', filter: true, enableCellChangeFlash: true },
        { headerName: 'First Name', field: 'first_name', filter: true, enableCellChangeFlash: true },
        { headerName: 'Last Name', field: 'last_name', filter: true, enableCellChangeFlash: true },
        { headerName: 'Role', field: 'role_name', filter: true, enableCellChangeFlash: true },
        {
          headerName: 'Edit',
          colId: 'edit',
          cellRendererFramework: TemplateRendererComponent,
          cellRendererParams: {
            ngTemplate: this.editCell
          },
          cellClass: 'no-border',
        },
        {
          headerName: 'Alert Preferences',
          colId: 'alert',
          cellRendererFramework: TemplateRendererComponent,
          cellRendererParams: {
            ngTemplate: this.alertCell
          },
          cellClass: 'no-border',
        }
      ]);
    }
  }

  /**
   * Adds the data to the grid
   */
  setGridRowData(data) {
    this.gridOptions.api.setRowData(data);
  }

  returnFormHTML(){
    return "&ltform [formGroup]=\"emailPrefsForm\"&gt&ltdiv class=\"row\"&gt&ltdiv class=\"col-12\"&gt&ltdiv class=\"form-group floating-label\"&gt&ltlabel for=\"email\"&gtHours between receiving alerts&lt/label&gt&ltinput formControlName=\"hours\" type=\"number\" class=\"form-control\" id=\"hours\" aria-describedby=\"hours\"placeholder=\"Enter hours\"&gt&lt/div&gt&lt/div&gt&lt/div&gt&lt/form&gt";
  }

  addUserRow(user) {
    // Update grid
    user.first_name = user.firstName;
    user.last_name = user.lastName;
    this.gridOptions.api.updateRowData({ add: [user] });
    let rowNode = this.gridOptions.api.getRowNode(String(user.id))
    this.gridOptions.api.flashCells({ rowNodes: [rowNode] })
    alertify.success('User added.')
  }

  //*********************
  // API CALLS
  //********************* 

  /**
 * Sends request to create a new user and handles the response
 * @param user user to be created
 */
  createNewUserHandler(user) {
    this.loading = true;
    let self = this;
    this.userFormSubmitted = true;
    if(user.cerberi == ""){
      user.cerberi = [];
    }
    this.userService.createUser(user)
      .pipe(first())
      .subscribe(
        data => {
          // Set user object values
          user.id = data.id;

          this.addUserRow(user);
          this.userFormSubmitted = false;
          this.loading = false;
          self.closeEditPanel();
        },
        error => {
          this.currentUser = null;          
          let msg = alertify.error("Error creating user.")
          msg.delay(Constants.ALERT_TIME);
          this.loading = false;
        });
  }

  /**
   * Sends request to edit a user and handles response
   * @param user user to be edited
   */
  editUserHandler(user) {
    this.loading = true
    this.userFormSubmitted = true;
    let self = this;
    //change empty cerberi string to empty array
    if(user.cerberi == ""){
      user.cerberi = [];
      let msg = alertify.warning("A user should have at least one DRGS site associated!");
      msg.delay(Constants.ALERT_TIME);
    }
    if(user.lastName && user.firstName){
      user.last_name = user.lastName;
      user.first_name = user.firstName;
    }
    
    //ensure we include the rolename.
    user.roleName = this.editUserFormFields.roleName.value;
    //ensure we include the email
    user.email = this.editUserFormFields.email.value;
    this.userService.editUser(user)
      .pipe(first())
      .subscribe(
        data => {
          // Gets user row
          let rowNode = this.gridOptions.api.getRowNode(String(user.id))
          user["first_name"] = user.firstName;
          user["last_name"] = user.lastName;
          // Updates user row data
          rowNode.setData(user)
          this.gridOptions.api.flashCells({ rowNodes: [rowNode] })
          alertify.success("User updated.")
          this.userFormSubmitted = false;
          this.loading = false;
          self.closeEditPanel();
        },
        error => {          
          let msg = alertify.error("Error updating user.")
          msg.delay(Constants.ALERT_TIME);
          this.loading = false;
        });
  }

  editUserPrefs(json){
      this.userService.editUserPrefs(json).subscribe(
        data => {          
        },
        error => {          
          let msg = alertify.error("Error setting user alert preferences.");
          msg.delay(Constants.ALERT_TIME);
        });    
  }

  //*********************
  // EDIT USER FUNCTIONALITY
  //********************* 

  /**
   * Opens the edit user pane and populates with user data 
  */
  editUser(user) {
    this.editUserForm.controls.requestedSites.enable();
    if(user){
      this.userNotNew = true;
      this.editUserForm.controls.email.disable();
    }else{
      this.userNotNew = false;
      this.editUserForm.controls.email.enable();
    }
      
    // Check to see if the user has been edited and either prompt to discard changes or move to editing the user
    if (this.checkEdited(this.currentUser)) {
      let self = this
      alertify.confirm('Achelous', 'Discard changes?',
        // If they choose to discard their changes, update the form
        function () {
          self.updateEditedUserDisplay(user);
          alertify.notify('Changes discarded', 'custom')
          if (user) {
            alertify.success('Now editing ' + user.email)
          }
        },
        // If they choose to not discard, do nothing
        function () {

        });
    } else {
      if(user)
        user = this.gridOptions.api.getRowNode(user.id).data;
      this.updateEditedUserDisplay(user);
      if (user) {
        //check to see if the user we're editing is the user that is logged in
        //if so, enable email preference button
        if(JSON.parse(localStorage.getItem("currentUser")).email == user.email)
          this.editUserSelected = true;
        alertify.success('Now editing ' + user.email)
      }
    }
    this.editUserForm.controls.requestedSites.disable();
  }

  /**
   * Updates display of the user edit form
   * @param user user form field values
   */
  updateEditedUserDisplay(user) {
    this.editUserSelected = false;
    this.currentUser = user;
    this.openEditPanel();
    this.updateUserForm(user);
  }

  /**
   * Checks to see if there are any changes between the user being edited and the form fields
   * @param user current user value (user being edited)
   */
  checkEdited(user) {
    if (user) {
      return (this.editUserFormFields.email.value != user.email ||
        this.editUserFormFields.firstName.value != user.first_name ||
        this.editUserFormFields.lastName.value != user.last_name ||
        this.editUserFormFields.roleName.value != user.role_name)
        this.editUserFormFields.cerberi.value != user.cerberi
    } else {
      return false;
    }
  }

  /**
   * Discards any changes made to the user form (called on click of discard button)
   */
  discardUser() {
    let self = this;
    if (this.checkEdited(this.currentUser)) {
      alertify.confirm('Achelous', 'Discard changes?',
        // If they choose to discard their changes, update the form
        async function () {
          alertify.notify('Changes discarded', 'custom');
          await Utility.delay(300);
          self.currentUser = null;
          self.closeEditPanel();
        },
        // If they choose to not discard, do nothing
        function () {

        });
    } else {
      this.closeEditPanel()
    }
  }

  checkDeleteUser(row) {
    let self = this;
    alertify.confirm('Achelous', 'Delete User?',
      // If they confirm, delete the user
      async function () { 
        self.deleteUser(row);
      },
      // Do nothing if Cancel is clicked
      function () {

      });
  }

  deleteUser(row){
    let email = row.email;
    let user = new User(row);
    let json = {
      email: email
    }
    this.userService.deleteUser(json).subscribe(
      data => {    
        alertify.success("User has been deleted"); 
        this.gridOptions.api.updateRowData({ remove: [user] }); 
      },
      error => {        
        let msg = alertify.error("Error deleting user.");
        msg.delay(Constants.ALERT_TIME);
      }); 
  }

  /**
  * Handles edit user form submit
  */
  submitEditUser() {

    // removes text field from cerberi values
    // if(this.editUserForm.value.cerberi != null){
    //   this.editUserForm.value.cerberi = this.editUserForm.value.cerberi.map((current) => {
    //     return current.id
    //   })
    // }
    
    this.editUserFormFields.requestedSites.enable();
    let user = new User(this.editUserForm.value)
    this.editUserFormFields.requestedSites.disable();
    user.role_name = this.editUserForm.value.roleName;

    let newUser = this.currentUser == null

    // Stop here if form is invalid
    if (this.editUserForm.invalid) {
      if(this.editUserFormFields.password.errors && this.editUserFormFields.password.errors.minlength){        
        let msg = alertify.error("Password must be at least 16 characters long.");
        msg.delay(Constants.ALERT_TIME);
      }else{        
        let msg = alertify.error("Form error. Check and resubmit.");
        msg.delay(Constants.ALERT_TIME);
      }
      
      return;
          
    }

    this.currentUser = user

    // If the user is new, create the new user
    if (newUser) {
      this.createNewUserHandler(user);
    }
    // If the user is being edited, edit the user
    else {
      if(user.roleName == "Pre-Confirmation" || user.roleName == "Pending"){        
        let msg = alertify.error("Role cannot be Pre-Confirmation or Pending");
        msg.delay(Constants.ALERT_TIME);
        return;
      }
      this.editUserHandler(user);
      let summaryPeriod = this.editUserFormFields.interval.value * 60 * 60;
      let cerberi = {};
      let alert_types = {
        "New Interference Event": false,       // each alert type may be muted...
        "Interference Event Severity": false,
        "Overdue Cerberus": false,
        "Cerberus Disk Space": false
      }
      if(this.editUserForm.value.muteList){
        for (let i = 0; i < this.editUserForm.value.muteList.length; i++) {
          cerberi[this.editUserForm.value.muteList[i]] = {
            muted: true,
            "alert_types": alert_types
          };
        }
      }      
      cerberi = this.checkUserCerberiPref(cerberi);
      let json = {
        email: user.email,
        mute_all: false,
        summary_period_s: summaryPeriod,
        cerberi: cerberi
      }
      this.editUserPrefs(json);
    }
  }

  checkUserCerberiPref(cerberi){
    let savedCerb = this.userPrefs.cerberi;
    let keys = Object.keys(savedCerb);
    let alert_types = {
      "New Interference Event": false,       // each alert type may be muted...
      "Interference Event Severity": false,
      "Overdue Cerberus": false,
      "Cerberus Disk Space": false
    }
    for(let i=0; i<keys.length;i++){
      //if there isn't a value with this key, we must add it
      if(!cerberi[keys[i]]){
        cerberi[keys[i]] = {
          muted: false,
          "alert_types": alert_types
        };
      }
    }
    return cerberi;
  }

  /**
   * Updates user form fields with user object 
  */
  updateUserForm(user) {
    if (user) {
      //get the logged in user's role
      let curUserRole = JSON.parse(localStorage.getItem("currentUser")).roleName;
      //user = this.gridOptions.api.getRowNode(user.id).data
      this.editUserFormFields.email.setValue(user.email)
      this.editUserFormFields.firstName.setValue(user.first_name)
      this.editUserFormFields.lastName.setValue(user.last_name)
      this.editUserFormFields.roleName.setValue(user.role_name)
      this.editUserFormFields.supervisor.setValue(user.supervisor)
      this.editUserFormFields.region.setValue(user.region)
      if(user.requested_sites){
        this.editUserFormFields.requestedSites.setValue(user.requested_sites)
      }else
        this.editUserFormFields.requestedSites.setValue(user.requestedSites)
      this.editUserFormFields.id.setValue(user.id)
      this.editUserFormFields.password.setValue('')
      if(this.editUserFormFields.roleName.value == "Super User"  || this.editUserFormFields.roleName.value == "Administrator"){
        this.editUserFormFields.cerberi.disable();
      }else if(curUserRole == "User" || curUserRole == "Restricted User"){
        this.editUserFormFields.cerberi.disable();
        this.editUserFormFields.roleName.disable();
      }
      else{
        this.editUserFormFields.cerberi.enable();
      }
      //remove any previous cerberi selected
      this.cerberiList.itemsList.items.forEach(item => this.cerberiList.unselect(item));
      if(user.cerberi != null){
        //loop through and for each of the user's cerberi
        for(let i =0; i<user.cerberi.length; i++){
          //To set the ng-select value we have to get the item from the list and then use the select() command on it
          let item = null;
          if(user.cerberi[i].name){
            item = this.cerberiList.itemsList.findByLabel(user.cerberi[i].name);
          }else
            item = this.cerberiList.itemsList.findItem(user.cerberi[i]);
          
          this.cerberiList.select(item);
        }
      }

      //remove Admin or Super User roles when necessary
      if(user.role_name == "Administrator"){
        for(let i = 0; i<this.userRoles.length; i++){
          if(this.userRoles[i].role_name == "Super User")
            this.userRoles.splice(i, 1);
        }
      }else if(user.role_name == "Super User"){
        for(let i = 0; i<this.userRoles.length; i++){
          if(this.userRoles[i].role_name == "Administrator")
            this.userRoles.splice(i, 1);
        }
      }else{
        for(let i = 0; i<this.userRoles.length; i++){
          if(this.userRoles[i].role_name == "Super User")
            this.userRoles.splice(i, 1);
        }
        for(let i = 0; i<this.userRoles.length; i++){
          if(this.userRoles[i].role_name == "Administrator")
            this.userRoles.splice(i, 1);
        }
      }

      let json = {email: user.email};
      this.userService.getUserPrefs(json).pipe(first())
      .subscribe(
        data => {
          this.userPrefs = data;
          if(data){
            this.cerberiMuteList.itemsList.items.forEach(item => this.cerberiMuteList.unselect(item));
            this.editUserForm.controls.interval.setValue(data.summary_period_s/3600);
            this.muteDropdownList = [];
            for(let i=0; i<Object.keys(this.userPrefs.cerberi).length; i++){
              let key = Object.keys(this.userPrefs.cerberi)[i];
              this.muteDropdownList.push({
                id: key,
                site_id: key,
                name: data.cerberi[key].cerb_name
              })
            }
            for(let i=0; i<Object.keys(this.userPrefs.cerberi).length; i++){
              let key = Object.keys(this.userPrefs.cerberi)[i];
              //if the cerberus is marked as muted, add it to the list. If not, continue.
              if(data.cerberi[key].cerb_muted == true){
                let item = this.cerberiMuteList.itemsList.findByLabel(this.userPrefs.cerberi[key].cerb_name);
                this.cerberiMuteList.select(item);
              }              
            }
          }
        },
        error => {          
          let msg = alertify.error("Error getting user alert preferences.");
          msg.delay(Constants.ALERT_TIME);
        });
      
        //disable requested sites as it should never be edited
        this.editUserFormFields.requestedSites.disable();
      
    } else {
      FormHelpers.resetForm(this.editUserForm, []);
    }
  }

  /**
   * Closes the edit user panel
   */
  closeEditPanel() {
    this.visiblePane = 'left';
  }

  /**
   * Opens the edit user panel
   */
  openEditPanel() {
    //reset the userRoles list
    this.userRoles = [...this.userRolesBackup];
    this.visiblePane = 'right';

    //Reset control
    this.editUserFormFields.requestedSites.enable();
  }

  /**
  * Convenience getter for easy access to login form fields
  */
  get editUserFormFields() { return this.editUserForm.controls; }

  //*********************
  // MISC FUNCTIONALITY 
  //********************* 

  /**
 * Takes a user id and array of users and returns the user matching the given id
 * @param data user data in array
 * @param userId user id
 */
  getUserById(data, userId) {
    for (let user of data) {
      if (user.id.toString() == userId) {
        return user;
      }
    }
  }

  /**
   * Alertify defaults
   */
  setAlertifyDefaults() {
    alertify.defaults.theme = {
      ok: "btn btn-primary",
      cancel: "btn btn-danger",
      input: "form-control"
    }
    alertify.defaults.notifier = {
      // auto-dismiss wait time (in seconds)  
      delay: 3,
      // default position
      position: 'bottom-right',
      // adds a close button to notifier messages
      closeButton: false,
    }
    alertify.defaults.overflow = false;
  }
}

@Component({
  selector: 'modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{title}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="editPrefsForm"> 
        <div class="form-group">
          <label for="interval">Interval in which you wish to receive alerts (hours)</label>
          <div class="input-group">
            <input formControlName="interval" id="interval" class="form-control" placeholder="24" name="interval" type="number" min="0" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
            oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">
          </div>
          <br>
          <div class="row">
            <div class="col-12">
              <label for="cerberiPopupList">Mute Notifications from:</label>
              <div class="form-group floating-label">
                <ng-select #cerberiPopupList formControlName="cerberiPopupList" [items]="muteDropdownList" multiple="true" bindLabel="name" bindValue="id" placeholder="Select Sites">                      
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="closeFunction()">{{closeBtnName}}</button>
    </div>
  `
})

export class ModalContentComponent implements AfterViewInit {
  title: string = "Alert Preferences for Email Notifications";
  closeBtnName: string = "Save";
  muteDropdownList = [];
  editPrefsForm: FormGroup;
  userPrefs:any;
  editFunction: any;
  hideFunction: any;
  user: any;
  data: any;
  checkUserCerberiPref: any;
  @ViewChild('cerberiPopupList') cerberiPopupList;


  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private userService: UserService,) {
    this.editPrefsForm = this.formBuilder.group({
    interval: [''],
    cerberiPopupList: ['']
  })}

  ngAfterViewInit() {
    let json = {
      email: this.user.email
    }

    this.userPrefs = this.data;
    let interval = this.data.summary_period_s/3600;
    this.editPrefsForm.controls.interval.setValue(interval);
          
    for(let i=0; i<Object.keys(this.userPrefs.cerberi).length; i++){
      let key = Object.keys(this.userPrefs.cerberi)[i];
      if(this.data.cerberi[key].cerb_muted == true){
        let item = this.cerberiPopupList.itemsList.findByLabel(this.userPrefs.cerberi[key].cerb_name);
        this.cerberiPopupList.select(item);
      } 
    } 
  }

  closeFunction(){
    let summaryPeriod = this.editPrefsForm.value.interval * 60 * 60;
      let cerberi = {};
      let alert_types = {
        "New Interference Event": false,       // each alert type may be muted...
        "Interference Event Severity": false,
        "Overdue Cerberus": false,
        "Cerberus Disk Space": false
      }
      for(let i = 0; i<this.editPrefsForm.value.cerberiPopupList.length; i++){
        cerberi[this.editPrefsForm.value.cerberiPopupList[i]] = {muted: true,
        "alert_types": alert_types};
      }
      cerberi = this.checkUserCerberiPref(cerberi);
      let json = {
        email: this.user.email,
        mute_all: false,
        summary_period_s: summaryPeriod,
        cerberi: cerberi
      }
      this.editFunction(json);
      this.hideFunction();
  }
}