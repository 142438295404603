import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlotlyModule } from 'angular-plotly.js';
import { ReportsComponent, SingleReportDialog, MonthlyReportDialog, DetailedReportDialog } from './reports.component';
import { CoreModule } from 'src/app/core/core.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatDialogModule } from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import {FormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MonthDatePickerComponent } from './month-date-picker/month-date-picker.component'

@NgModule({
  declarations: [ReportsComponent, SingleReportDialog, MonthlyReportDialog, DetailedReportDialog, MonthDatePickerComponent],
  entryComponents:[
    SingleReportDialog,
    MonthlyReportDialog,
    DetailedReportDialog
  ],
  imports: [
    CommonModule,
    PlotlyModule,
    CoreModule,
    AppRoutingModule, 
    MatDialogModule,
    FontAwesomeModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule, 
    NgbModule,
    NgSelectModule
  ],
  providers: [
    ReportsComponent
  ]
})
export class ReportsModule { }
