import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from "socket.io-client";

@Injectable({ providedIn: 'root' })
export class SocketService {

    private apiUrl: any;
    private socket: any;

    constructor(
        @Inject('API_URL') apiUrl,
    ) { 
        this.apiUrl = apiUrl
//        this.socket = io(apiUrl)
    }

    /**
     * When a message is received
     * @param eventName String for the name of the event
     */
    onReceiveMessage(eventName){
//        return Observable.create(observer => {
//            this.socket.on(eventName, data => {
//                observer.next(data)
//            })
//        })
    }

    /**
     * Sends a message to the backend
     * @param eventName String for the name of the event
     * @param eventMessage What you want to be sent to the backend
     */
    sendMessage(eventName, eventMessage){
//        this.socket.emit(eventName, eventMessage)
    }
}
