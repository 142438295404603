import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageDivComponent } from './message-div.component';

@NgModule({
  declarations: [MessageDivComponent],
  imports: [
    CommonModule
  ],
  exports: [MessageDivComponent]
})
export class MessageDivModule { }
