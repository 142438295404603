import { Component, OnInit } from '@angular/core'

@Component({
    selector: './cage-status',
    templateUrl: './cage-status.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class CageStatusComponent implements OnInit {

    ngOnInit() {

    }
}