import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideAnimationDirective } from './slide-animation/slide-animation.directive';
import { SmoothHeightDirective } from './smooth-height/smooth-height.directive';

@NgModule({
  declarations: [SlideAnimationDirective, SmoothHeightDirective],
  imports: [
    CommonModule
  ],
  exports: [SlideAnimationDirective, SmoothHeightDirective]
})
export class AnimationsModule { }
