import { Component, OnInit } from '@angular/core';
import { CerberiService } from 'src/app/core/services/cerberi.service';
import { first, reduce } from 'rxjs/operators';
import * as Plotly from 'plotly.js/dist/plotly.js';
import { Constants,  UserNotificationsService, AuthenticationService, SocketService, SiteDetailsService} from 'shared-front-end';
import * as alertify from 'alertifyjs';
import { Utility } from 'shared-front-end'
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-site-dashboard',
  templateUrl: './site-dashboard.component.html',
  styleUrls: ['./site-dashboard.component.css']
})

export class SiteDashboardComponent implements OnInit {
  private allMaps = [];
  private siteData = [];
  private isAdmin = false;
  private isSuper = false;
  private userData = [];
  private statusData = [];
  private notification;
  private currentUser;
  private user_id;
  private user_role;
  private ms_to_min = 60000;
  private subscription: Subscription;
  static refresher = null; // refresher is static so that only one refresh interval timer exists.

  constructor(
    private cerberiService: CerberiService,
    private userNotificationsService: UserNotificationsService,
    private socketService: SocketService,
    private siteDetailsService: SiteDetailsService,
  ) { }

  ngOnInit() {

    // Handles a user sync event via websocket
    // this.socketService.onReceiveMessage('newInterferenceEvent').subscribe(userData => {
    //   console.log("newInterferenceEvent")
    //   this.getSiteInfoInterferenceStatus()
    //   this.getNotifications()
    // })

    //we need to identify the cerberi systems that this user is authorized to see
    //obtain user id and role
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.user_id = this.currentUser['id']
    this.user_role = this.currentUser['roleName']

    // get site info including location and interfernce
    // status/severity for active interference
    this.getSiteInfoInterferenceStatus()

    //check Achelous disk space
    if(this.user_role == "Administrator"){
      this.isAdmin = true;
      this.cerberiService.getDiskSpace()
      .pipe(first())
      .subscribe(
        data => {
          if (data != null) {
            let diskStats = JSON.parse(data.stats_json);
            let usePercent = diskStats.use_percent.split('%');
            usePercent = parseInt(usePercent);
            let percent = 100 - usePercent;
            if (usePercent >= 90) {
              let avail = diskStats.avail;
              let msg = alertify.warning("Server disk space is running low! Only " + percent + "% (" + avail + ") remaining.");
              msg.delay(Constants.ALERT_TIME);
            }
          }
        },
        error => {          
          let msg = alertify.error("Error getting disk space");
          msg.delay(Constants.ALERT_TIME);
        });
    } 
    
    if(this.user_role == "Super User"){
      this.isSuper = true;
    }

    //set an interval of 10 seconds
    const source = interval(10000);
    this.subscription = source.subscribe(val=> this.getSiteInfoInterferenceStatus());

    // get user notifications
    //this.getNotifications()

    //Sockets *********************************
 //  this.socketService.onReceiveMessage('newInterferenceEvent').subscribe(interferenceData => {
 //    console.log("newInterferenceEvent")
 //    this.getSiteInfoInterferenceStatus()
 //    this.getNotifications()
 //  })
 
    // this.socketService.onReceiveMessage('updateInterferenceEvent').subscribe(interferenceData => {
    //   console.log("updateInterferenceEvent")
    //   this.getSiteInfoInterferenceStatus()
    //   this.getNotifications()  // currently there are no user notifications for interference update
    // })
 
    // this.socketService.onReceiveMessage('endInterferenceEvent').subscribe(interferenceData => {
    //   console.log("endInterferenceEvent")
    //   this.getSiteInfoInterferenceStatus()
    //   this.getNotifications()  // currently there are no user notifications for interference end
    // })

    //End Sockets *********************************

  } //end - oninit

  //must stop calling this so unsubscribe when done
  ngOnDestroy(){
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  getSiteInfoInterferenceStatus() {
    // Find interference event time threshold - 1 week in the past
    //let lastweek = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));

    this.cerberiService.getUserSites(this.user_id, this.user_role)
      .pipe(first())
      .subscribe(
        data => {
          this.siteData = data.cerberi;
          console.log("sitedata:", this.siteData)
          if(this.siteData){
            let siteCount = this.siteData.length;
          let iter = 0;
          // Find event time threshold - 24 hrs in past
          let yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
          for (let site of this.siteData) {
            //handle no data
            if(!site["most_recent_event"]){
              site["most_recent_event"] = "No interference available";
            }else{
              //parse a date that arrives in the following format: "2022-12-07 19:14:56.048891"
              let mostRecentDateSplit = site["most_recent_event"].split(" ");
              let mostRecentTimeSplit = mostRecentDateSplit[1].split(":")
              let mostRecentDateSplitSub = mostRecentDateSplit[0].split("-")
              let mostRecentSeconds = mostRecentTimeSplit[2].split(".")[0]
              let mostRecentDate = new Date(Date.UTC(mostRecentDateSplitSub[0], mostRecentDateSplitSub[1]-1,mostRecentDateSplitSub[2],mostRecentTimeSplit[0],mostRecentTimeSplit[1], mostRecentSeconds))
              if (this.isValidDate(mostRecentDate)) {
                let displayDate = mostRecentDate.toUTCString().split(",")[1]
                site["most_recent_event"] = displayDate;
              }
              if(!site["temp_mon_status"]){
                let tempMonObj = {"PsmsDrawerTemp": "N/A", "PsmsDrawerHumidity": "N/A"}
                site["temp_mon_status"] = tempMonObj
              }
            }
               
            if(!site["last_day_duration"]){
              site["last_day_duration"] = "No interference available";
            }

            site["notification_count"] = site.unviewed_event_count;
            
            //extract and insert location data
            if(site.site_location != null && site.site_location.length > 0){
              let index = site.site_location.indexOf("POINT(")+6;
              let location = site.site_location.substring(index, site.site_location.length-1);
              let split = location.split(" ");
              site["lon"] = split[0];
              site["lat"] = split[1];
              //Alert if low disk space
              this.checkDiskSpaceAlert(site.disk_stats, site.name);
            }

            //set to gray if not seen in 1 hour
            if(site && site.last_seen){
              let str = site.last_seen.substring(0, site.last_seen.length-4);
              let lastSeen = new Date(str+"+0000");
              let now = new Date();
              const onehour = 60 * 60 * 1 * 1000;
              let isPastDue = (now.getTime()-lastSeen.getTime()) > onehour;
              if(isPastDue){
                site.interference_status = 3;
              }
            }            
            
            this.userNotificationsService.getBySite(site.id, yesterday, true)
              .pipe(first())
              .subscribe(
                data => {
                  if (data.events.length > 0) {
                    for (let i = 0; i < this.siteData.length; i++) {
                      if(this.siteData[i].interference_status == 3)
                        continue;
                      if (this.siteData[i].id == data.cerberus_id) {
                        this.siteData[i] = { ...this.siteData[i], events: data.events }
                        for (let j = 0; j < this.siteData[i].events.length; j++) {
                          if (this.siteData[i].events[j].end_dtm == null) {
                            this.siteData[i] = { ...this.siteData[i], interference_status: this.siteData[i].events[j].severity }
                          }
                        }
                      }

                      // We determine the highest severity among un-viewed interference events
                      this.siteData[i].highestSeverity = "none";
                      //null check
                      if (this.siteData[i].events) {
                        //if there are any unviewed events, set color to yellow by default
                        if (this.siteData[i].unviewed_event_count > 0)
                          this.siteData[i].highestSeverity = "yellow";
                        //if we find a single red unviewed event, set color to red
                        for (let j = 0; j < this.siteData[i].events.length; j++) {
                          if (this.siteData[i].events[j].severity == "2" && this.siteData[i].events[j].viewed == false) {
                            this.siteData[i].highestSeverity = "red";
                            break;
                          }
                        }
                      }
                    }
                  }
                  
                  if(this.notification == null){
                    this.notification = data;
                  }else{
                    this.notification = Object.assign(this.notification, data);
                  }
                  
                  iter += 1;
                  if(iter == siteCount)
                    this.associateLocationNoificationCount();
                },
                error => {                  
                  let msg = alertify.error("Error getting notifications");
                  msg.delay(Constants.ALERT_TIME);
                });
          }
          }
          
          //Removed map functionality for now
          //this.makeMaps(this.siteData)
        },
        error => {          
          let msg = alertify.error("Error getting sites");
          msg.delay(Constants.ALERT_TIME);
        });
  }

  // Accepts a color string and returns a color code. 
  // Defaults to bg-light color
  getColor(color){
    switch(color){
      case "none":
        return "#f5f5f5";
      case "yellow":
        return "rgba(249, 220, 31, 0.6)";
      case "red":
        return "rgba(230, 39, 57, 0.6)";
      default:
        return "#f5f5f5";
    }
  }

  checkDiskSpaceAlert(diskStats, siteName){
    let usePercent = diskStats.Use_percent.split('%');
    usePercent = parseInt(usePercent);
    let percent = 100-usePercent;
    if(usePercent >= 90){
      let avail = diskStats.Avail;
      let msg = alertify.warning(siteName + " server disk space is running low! Only "+ percent+"% (" + avail + ") remaining.");
      msg.delay(Constants.ALERT_TIME);
    }
  }

  getNotifications() {
    // Find event time threshold - 24 hrs in past
    let yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));

    // Gets user notifications
    this.userNotificationsService.getByUser(this.user_id, yesterday, undefined, 'interference_event_start')
    .pipe(first())
    .subscribe(
      data => {
        this.notification = data;
        this.associateLocationNoificationCount();
      },
      error => {        
        let msg = alertify.error("Error getting notifications");
        msg.delay(Constants.ALERT_TIME);
      });
  }

  // Associate the notifications per each cerberus (this.notification)
  // to the cerebus location information (this.siteData)
  associateLocationNoificationCount() {
    // For each cerberus site find the notification index that matches cerberus_id,
    // associate the int_count
    if (this.notification) {
      for (let site of this.siteData) {
        site["notification_count"] = site.unviewed_event_count;
        let data = site.events;
        if(data != null){
          site["last_day_duration"] = this.calculateEventDurationLast24Hours(data);          
        }
      }
    }
  }

  calculateEventDurationLast24Hours(eventData) {
    let yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
    let totalTime = 0;
    for (let event of eventData) {
      if (event.end_dtm != null) {
        let start_date = new Date(event.start_dtm)
        let end_date = new Date(event.end_dtm);
        let diff = 0;
        //check to see if end date is after our yesterday date
        if(end_date > yesterday){
          //check to see if our start date is also before yesterday. If so, use diff between start and end. If not use diff between end and yesterday          
          if(start_date > yesterday){
            diff = end_date.getTime() - start_date.getTime()
          }else{
            diff = end_date.getTime() - yesterday.getTime();
          }
        }
        totalTime += diff;
      }
    }
    //determine duration as a string
    return(this.msToTime(totalTime));
  }

  isValidDate(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {  // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  msToTime(duration) {
    let hours,minutes,seconds;
    hours = Math.floor(duration/1000/60/60);
    minutes = Math.floor((duration/1000/60/60 - hours)*60);
    seconds = Math.floor(((duration/1000/60/60 - hours)*60 - minutes)*60);
  
    return hours + " hours " + minutes + " minutes and " + seconds + " seconds.";
  }


  /**
   * Loops through site data and updates each graph.
   * @param data site data
   */
  makeMaps(data) {
    for (let site of data) {
      this.updateGraphData(site);
    }
  }


  /**
     * Updates the plotly data object with the json response
     * @param data 
     */
  updateGraphData(site) {

    if (site.lat) {
      // Get basic map configuration
      let mapGraph = Constants.DashboardMaps;

      // Put in site locations
      // Lat/Lon switched since they are stored incorrently in DB
      mapGraph.data[3].text = [site.name];
      mapGraph.data[3].hoverinfo = site.name;
      mapGraph.data[3].lat = [site.lat];
      mapGraph.data[3].lon = [site.lon];

      mapGraph.layout.mapbox.center = {
        lat: site.lat,
        lon: site.lon,
      }
  
      // Add to maps array object
      this.allMaps.push(Utility.copy(mapGraph));
    }
  }

  /**
   * Takes a point and splits it into lat/lon, returns lat, lon
   * @param point 
   */
// parseLatLon(point) {
//   let cleaned_point = point.substring(1, point.length - 1);
//   let split_point = cleaned_point.split(',');
//   let lat = +split_point[0];
//   let lon = +split_point[1];
//   return [lat, lon]
// }

}
