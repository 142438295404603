import { Component, OnInit } from '@angular/core'

@Component({
    selector: './antenna-controller-faq',
    templateUrl: './antenna-controller-faq.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class AntennaControllerFAQComponent implements OnInit {

    ngOnInit() {

    }
}