import { Component, OnInit, ViewChild, TemplateRef, ElementRef, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router'
import * as Plotly from 'plotly.js/dist/plotly.js';
import { CerberiService } from 'src/app/core/services/cerberi.service';
import { first, reduce } from 'rxjs/operators';
import { Constants } from 'shared-front-end';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-site-location-map',
  templateUrl: './site-location-map.component.html',
  styleUrls: ['./site-location-map.component.css'],
  animations: []
})


export class SiteLocationMapComponent implements OnInit {
  public rowInstantiator = class {
    public row;
    
    constructor(rowName) {
      this.row = {name: rowName};
    }

    get rowInfo() {
      return this.row;
    }
  };

  visiblePane = 'right';

  public siteTable = {
    title: 'Sites',
    pagination: true,
    rowStyle: {},
    columnDefs: [
      {headerName: 'Sites', field: 'name', filter: true, resizable: true, enableCellChangeFlash: true}
    ],
    animateRows: true,
    rowData: null
  };

  
  constructor(
    private router: Router,
    private cerberiService: CerberiService
  ) { }

  public mapGraph = Constants.SitesMap;
  private siteData = [];
  private currentUser;
  private user_id;
  private user_role;



  ngOnInit() {
    //we need to identify the cerberi systems that this user is authorized to see
    //obtain user id and role
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.user_id = this.currentUser['id']
    this.user_role = this.currentUser['roleName']
    
    //used to get arrow facing the right way upon startup
    this.switchArrow();
    //I'm creating a conflict
    
    //this.getSiteInfoInterferenceStatus()
    
    this.getSiteInfo()
    

    
  // this.cerberiService.getUserSites(this.user_id,this.user_role)
  //   .pipe(first())
  //   .subscribe(
  //     data => {
  //       for (let site of data) {
  //         if (site.name == "St. Louis") {
  //           site.status = 2
  //         } else if (site.name == "Cincinatti") {
  //           site.status = 1
  //         } else if (site.name == "Rock Island") {
  //           site.status = 3
  //         } else {
  //           site.status = 0
  //         }
  //       }

  //       this.siteData = data;
  //       this.updateGraphData(data);
  //     },
  //     error => {

  //     });
  }

  getSiteInfo() {
    // Find interference event time threshold - 1 week in the past
    let lastweek = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));

    this.cerberiService.getUserSites(this.user_id, this.user_role, lastweek)
      .pipe(first())
      .subscribe(
        data => {
          this.siteData = data.cerberi;
          this.populateGrid(data);
          this.updateGraphLocData(data);
        },
        error => {
          alertify.error("Error getting sites");
        });
  }

/* Old method for getting the sites, considers interference unlike getSiteInfo() which is being used for the map
  getSiteInfoInterferenceStatus() {
    // Find interference event time threshold - 1 week in the past
    let lastweek = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));

    this.cerberiService.getUserSites(this.user_id, this.user_role, lastweek)
      .pipe(first())
      .subscribe(
        data => {
          this.siteData = data;
          this.updateGraphData(data);
        },
        error => {
          alertify.error("Error getting sites");
        });
  }
*/
  // Handles any mouse click events on the map
  plotlyClick(mouseEvent: any): void {
    if (mouseEvent.points != undefined) {
      if (mouseEvent.event.ctrlKey == true) {
        // If ctrl is held down, zoom to point
        this.zoomToPoint(mouseEvent.points[0].lat, mouseEvent.points[0].lon)
      } else {
        // Otherwise go to site details page
        this.router.navigate(['/site-details'])
      }
    }
  }

  // Zooms the map to a lat/lon
  zoomToPoint(lat, lon) {
    this.mapGraph.layout.mapbox.center = {
      lat: lat,
      lon: lon
    };
    this.mapGraph.layout.mapbox.zoom = 12
    Plotly.redraw('sites-map')
  }

  /**
   * Old method, Updates the plotly data object with the json response,
   * doesn't work but would consider interference level if it did
   * @param data 
   */
  /*
  updateGraphData(data) {
    if (data) {
      for (let site of data) {
        if (site.interference_status != null) {
          this.mapGraph.data[site.interference_status].text.push(site.name);
          this.mapGraph.data[site.interference_status].lat.push(site.lat);
          this.mapGraph.data[site.interference_status].lon.push(site.lon);
        }

      }
      Plotly.redraw('sites-map')
    }
  }
*/
  /**
   * Updates the plotly dat object using the json response, but does so without regards to interference level
   * @param data 
   */
  updateGraphLocData(data) {
    if (data) {
      var len = data.cerberi.length;
      var i;
      for (i = 0; i < len; i++) {

        /* currently the json response does not include interference, so "unknown" interference
           (3) is being used. IF interference needs to be used inthe future then put right here would be where you
           would change what array loc is being used */
        this.mapGraph.data[3].text.push(data.cerberi[i].name);
        this.mapGraph.data[3].hoverinfo.push(data.cerberi[i].name);

        let coordinates = this.parseLoc(data.cerberi[i].site_location);

        this.mapGraph.data[3].lat.push(coordinates[0]);
        this.mapGraph.data[3].lon.push(coordinates[1]);       
      }
      this.mapGraph.data[3].marker.color = "rgba(3, 205, 230,0.6)";

      Plotly.redraw('sites-map')
    }
  }

  /**
   * parses lat, lon out of a location data string
   * @param loc_data 
   * @returns parsed coordinates
  */
  parseLoc(loc_data) {
    var i = 0;
    while(i < loc_data.length) {
      if (loc_data.substring(i, i+1) == "(") {
        let coords = this.parsingLatLon(loc_data.substring(i));
        return coords;
      }
      i++;
    }
    /* returns null if the location data doesn't have coordinates in the right format */
    return null;
  }

/* durther parses lat lon out of a string*/
  parsingLatLon(point) {
    let cleaned_point = point.substring(1, point.length - 1);
    let split_point = cleaned_point.split(' ');
    let lon = +split_point[0];
    let lat = +split_point[1];
    return [lat, lon]
  }

  
  /* Not used anymore since it parses the wrong format, a whitespace is the delineator now
     parseLatLon(point) {
      let cleaned_point = point.substring(1, point.length - 1);
      let split_point = cleaned_point.split(',');
      let lat = +split_point[0];
      let lon = +split_point[1];
      return [lat, lon]
    }
  */

/**
   * Toggles which pane is visible
   */
 togglePane() {
  this.visiblePane = (this.visiblePane == 'right') ? 'left' : 'right'
 }

/**
 * switches the arrow for the pane
 */
  switchArrow() {
    document.getElementById("arrowAnimationTrackerCheckbox").click();
    
  }

/**
   * Sets the columns of the grid to fill width
   */
 onGridReady(grid: any) {
  grid.api.ensureColumnVisible();
 }

 populateGrid(data) { 
  this.setGridRowData(data);
}

  /**
   * Adds the data to the grid
  */
  setGridRowData(data) {
    var nameArr = [];
    var rowArr = [];
    var i;
    for(i = 0; i < data.cerberi.length; i++) {
      let newRow = new this.rowInstantiator(data.cerberi[i].name);
      rowArr.push(newRow.row);
    }

    if(this.siteTable.rowData == null) {
      this.siteTable.rowData = [];
    } 
    this.siteTable.rowData = (rowArr);
  }

/**
 * allows a user to auto-zoom to a site by clicking on the site in the ag-Grid
 */  
  onCellClicked(clickedSite) {
    let siteName = clickedSite.data.name;
    var i = 0;
    var len = this.siteData.length;

    for(i = 0; i < this.siteData.length; i++) {

      if(siteName == this.siteData[i].name) {
        let coord = this.parseLoc(this.siteData[i].site_location);
        this.zoomToPoint(coord[0], coord[1]);
        this.switchArrow();
        this.togglePane();
        break;
      }
    }    
  }
}
