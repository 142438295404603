import { Component, Input } from '@angular/core';

@Component({
    selector: 'message-div',
    templateUrl: 'message-div.component.html',
    styleUrls: ['message-div.component.css']
})

export class MessageDivComponent {
    @Input() messageVariable;
}