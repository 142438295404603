import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpComponent } from './help.component';
import { CoreModule } from 'src/app/core/core.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MainContentComponent } from './mainContent/main-content.component';
import { HelpTopicListComponent } from './mainContent/mainTopicList/help-topic-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { AchelousHeroComponent } from './mainContent/heroes/achelousHero/achelous-hero.component';
import { DamsntHeroComponent } from './mainContent/heroes/damsntHero/damsnt-hero.component';
import { ObjectiveComponent } from './mainContent/topic/topics/introduction/objective/objective.component';
import { DRGSComponent } from './mainContent/topic/topics/introduction/drgs/drgs.component';
import { WaterManagementComponent } from './mainContent/topic/topics/introduction/water-management/water-management.component';
import { GoesDCSInterferenceConcernsComponent } from './mainContent/topic/topics/introduction/interferenceConcerns/goesdcs-interference-concerns.component';
import { AntennaControllerComponent } from './mainContent/topic/topics/damsnt-drgs/antennaController/antenna-controller';
import { CleaningMaintenanceComponent } from './mainContent/topic/topics/damsnt-drgs/cleaningMaintenance/cleaning-maintenance';
import { EthernetFiberSystemComponent } from './mainContent/topic/topics/damsnt-drgs/ethernetFiberSystem/ethernet-fiber-system';
import { DRGSEquipmentComponent } from './mainContent/topic/topics/damsnt-drgs/drgsEquipment/drgs-equipment';
import { ParabolicDishComponent } from './mainContent/topic/topics/damsnt-drgs/parabolicDish/parabolic-dish';
import { RFFiberComponent } from './mainContent/topic/topics/damsnt-drgs/RFFiberModule/rf-fiber';
import { DAMSNTOverviewComponent } from './mainContent/topic/topics/damsnt-drgs/systemOverview/damsnt-overview';
import { AutoFailOverComponent } from './mainContent/topic/topics/damsntsoftware/autofailOver/auto-fail-over.component';
import { CageStatusComponent } from './mainContent/topic/topics/damsntsoftware/cageStatusPage/cage-status.component';
import { DataAcquisitionMonitoringComponent } from './mainContent/topic/topics/damsntsoftware/dcsDataAcquisition/data-acquisition-monitoring.component';
import { IIMPilotSetupComponent } from './mainContent/topic/topics/damsntsoftware/iimPilotSetup/iim-pilot-setup.component';
import { MoveCopySwapComponent } from './mainContent/topic/topics/damsntsoftware/moveCopySwap/move-copy-swap.component';
import { PrimeOperationsComponent } from './mainContent/topic/topics/damsntsoftware/primeOperations/prime-operations.component';
import { SetupMenuPreferencesComponent } from './mainContent/topic/topics/damsntsoftware/setupMenuPreferences/setup-menu-preferences.component';
import { SlotMenuComponent } from './mainContent/topic/topics/damsntsoftware/slotMenu/slot-menu.component';
import { SlotStatusPageComponent } from './mainContent/topic/topics/damsntsoftware/slotStatusPage/slot-status-page.component';
import { SocketStatusComponent } from './mainContent/topic/topics/damsntsoftware/socketStatusPage/socket-status.component';
import { WindowMenuComponent } from './mainContent/topic/topics/damsntsoftware/windowMenu/window-menu.component';
import { ViewMenuComponent } from './mainContent/topic/topics/damsntsoftware/viewMenu/view-menu.component';
import { DRGSSiteDetailsComponent } from './mainContent/topic/topics/achelous/site-details/drgs-site-details.component';
import { AchelousDescriptionComponent } from './mainContent/topic/topics/achelous/description/achelous-description.component';
import { AchelousOverviewComponent } from './mainContent/topic/topics/achelous/overview/achelous-overview.component';
import { AchelousVideosComponent } from './mainContent/topic/topics/videos/achelous/achelous-videos.component';
import { DAMSNTVideosComponent } from './mainContent/topic/topics/videos/damsnt/damsnt-videos.component';
import { DRGSVideosComponent } from './mainContent/topic/topics/videos/drgs/drgs-videos.component';
import { AlarmCodesComponent } from './mainContent/topic/topics/troubleshooting/alarm-codes/alarm-codes.component';
import { CageSystemSetupComponent } from './mainContent/topic/topics/damsntsoftware/cageSystemSetup/cage-system-setup.component';
import { DamsNTFAQComponent } from './mainContent/topic/topics/troubleshooting/damsnt-faq/damsnt-faq.component';
import { AntennaControllerFAQComponent } from './mainContent/topic/topics/troubleshooting/antenna-controller-faq/antenna-controller-faq.component';
import { DRGSSateliteFAQComponent } from './mainContent/topic/topics/troubleshooting/drgs-satelite-faq/drgs-satelite-faq.component';
import { SpectrumMonitoringFAQComponent } from './mainContent/topic/topics/troubleshooting/spectrum-monitoring-faq/spectrum-monitoring-faq.component';
import { SpecificationsComponent } from './mainContent/topic/topics/specifications-warranties/specifications/specifications.component';
import { WarrantiesComponent } from './mainContent/topic/topics/specifications-warranties/warranties/warranties.component';
import { AchelousUsersComponent } from './mainContent/topic/topics/achelous/users/achelous-users.component';
import { AchelousReportsComponent } from './mainContent/topic/topics/achelous/reports/achelous-reports.component';



@NgModule({
  declarations: [
    HelpComponent, 
    MainContentComponent, 
    HelpTopicListComponent, 
    AchelousHeroComponent,
    DamsntHeroComponent,
    ObjectiveComponent,
    DRGSComponent,
    WaterManagementComponent,
    GoesDCSInterferenceConcernsComponent,
    AntennaControllerComponent, 
    CleaningMaintenanceComponent,
    EthernetFiberSystemComponent,
    DRGSEquipmentComponent,
    ParabolicDishComponent,
    RFFiberComponent,
    DAMSNTOverviewComponent,
    AutoFailOverComponent,
    CageStatusComponent,
    DataAcquisitionMonitoringComponent,
    IIMPilotSetupComponent,
    CageSystemSetupComponent,
    MoveCopySwapComponent,
    PrimeOperationsComponent,
    SetupMenuPreferencesComponent,
    SlotMenuComponent,
    SlotStatusPageComponent,
    SocketStatusComponent,
    WindowMenuComponent,
    ViewMenuComponent,
    AchelousDescriptionComponent,
    AchelousOverviewComponent,
    AchelousVideosComponent,
    DAMSNTVideosComponent,
    DRGSVideosComponent,
    DamsNTFAQComponent,
    AlarmCodesComponent,
    AntennaControllerFAQComponent,
    DRGSSateliteFAQComponent,
    SpectrumMonitoringFAQComponent,
    SpecificationsComponent,
    WarrantiesComponent,
    DRGSSiteDetailsComponent,
    AchelousUsersComponent ,
    AchelousReportsComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    AppRoutingModule,
    FontAwesomeModule,
  ]
})
export class HelpModule { }
