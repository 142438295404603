import { Directive, OnChanges, Input, HostBinding, ElementRef} from '@angular/core';

@Directive({
  selector: '[smoothHeight]',
})
export class SmoothHeightDirective implements OnChanges {

  @Input()
  smoothHeight;
  pulse: boolean;
  startHeight: number;

  constructor(private element: ElementRef) {}

  @HostBinding('@grow')
  get grow() {
    return { value: this.pulse, params: { startHeight: this.startHeight } };
  };
  
  // Calculates element height
  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  // Sets element height and toggles pulse
  ngOnChanges(changes) {
    this.setStartHeight();
    this.pulse = !this.pulse;
  }

  
}
