import { Component, OnInit } from '@angular/core'

@Component({
    selector: './rf-fiber',
    templateUrl: './rf-fiber.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class RFFiberComponent implements OnInit {

    ngOnInit() {

    }
}