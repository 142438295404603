import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { CerberiService } from 'src/app/core/services/cerberi.service';
import { Constants, UserNotificationsService, AuthenticationService, SocketService, SiteDetailsService } from 'shared-front-end';
import * as alertify from 'alertifyjs';
import { DomSanitizer } from '@angular/platform-browser';
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { until } from 'selenium-webdriver';

export interface DialogData {
  info: Object
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  styles: [`
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: rgb(2, 117, 216);
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(2, 117, 216, 0.5);
    }
  `]
})

export class ReportsComponent implements OnInit {
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  today: NgbDate
  maxDate: any
  notification = {}
  events: any
  yellowEvents = 0;
  redEvents = 0;
  peakEventLevel: any
  uptime: any
  currentUser: any
  userId: any
  userRole: any
  frequency

  @ViewChild('dp') datePicker: NgbCalendar;
  constructor(
    private cerberiService: CerberiService,
    private userNotificationsService: UserNotificationsService,
    private socketService: SocketService,
    private siteDetailsService: SiteDetailsService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    public dialog: MatDialog,
  ) {
    let now = new Date();
    this.reportsForm = this.formBuilder.group({
      reportPicker: [null],
      cerberi: [null],
      datePicker: [''],
      rangePicker: [''],
      monthPicker: {year: now.getFullYear(), month: now.getMonth()+1}
    })
    this.today = calendar.getToday();

    const current = new Date();
    this.maxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }
  interferenceData: any;
  buttonVisible = true;
  reportsForm: FormGroup
  showDatePicker = false;
  showRangePicker = false;
  showReportPicker = false;
  showCerberiPicker = false;
  showMonthPicker = false;
  notificationLoaded = false;
  durationLoaded = false;
  eventLoaded = false;
  dbLevelLoaded = false;
  uptimeLoaded = false;
  popupVisible = false;
  highestSeverity: any;
  reportTypes: any;
  cerberiList: any;
  selectedSite: any
  selectedDate: any;
  sites: any;
  siteCount: any;
  siteIter = 0;
  month;
  year;
  isLoading = false;

  ngOnInit() {
    this.buttonVisible = false;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.currentUser['id']
    this.userRole = this.currentUser['roleName']

    this.cerberiService.getAllSites().pipe()
      .subscribe(
        data => {
          this.cerberiList = data.cerberi;
        },
        error => {          
          let msg = alertify.error("Error getting cerberi sites.");
          msg.delay(Constants.ALERT_TIME);
        });

    this.reportTypes = [
      {
        name: "Single Report",
        value: "single"
      }, {
        name: "Detailed Report",
        value: "detailed"
      }, {
        name: "Report Over Time",
        value: "overTime"
      }, {
        name: "Monthly Report",
        value: "monthly"
      },
    ]
  }

  reportPickerChanged(){
    if(this.reportsForm.controls.reportPicker.value == 'monthly'){
      this.showMonthPicker = true;
      this.buttonVisible = true;
      this.showCerberiPicker = false;
      this.enableCalendar(null);
    }else{
      this.showCerberiPicker = true;
      this.showMonthPicker = false;
      if(this.selectedSite != null){
        this.enableCalendar(this.selectedSite);
      }
    }
  }

  enableReportPicker(cerberi) {
    if (cerberi) {
      this.showReportPicker = true;
      this.selectedSite = cerberi;
    } else {
      this.showReportPicker = false;
    }
  }

  enableCalendar(cerberi) {
    this.selectedSite = cerberi;
    if (this.reportsForm.controls.reportPicker.value == "single" || this.reportsForm.controls.reportPicker.value == "detailed") {
      this.showDatePicker = true;
      this.showRangePicker = false;
      
      if (this.fromDate) {
        this.buttonVisible = true;
      } else {
        this.buttonVisible = false;
      }

    } else if (this.reportsForm.controls.reportPicker.value == "overTime") {
      this.showRangePicker = true;
      this.showDatePicker = false;
      if (this.toDate) {
        this.buttonVisible = true;
      } else {
        this.buttonVisible = false;
      }
    } else {
      this.showDatePicker = false;
      this.showRangePicker = false;
    }

    this.hideArrows();
  }

  async hideArrows(){   
    setTimeout(() => {
      let arrows = Array.from(document.getElementsByClassName('ngb-dp-arrow') as HTMLCollectionOf<HTMLElement>);
      for(let i =0; i<arrows.length; i++){
        arrows[i].style.display = 'none';
      }
      this.resetCalendar();
    },1);    
  }

  //Called when report type is changed to remove any previous selections.
  resetCalendar() {
    //Remove the CSS that makes a button appear to be selected
    if (this.datePicker) {
      let textWhite = Array.from(document.getElementsByClassName("text-white"))
      textWhite.forEach(element => {
        element.classList.remove("text-white")
      })
      let bgPrimary = Array.from(document.getElementsByClassName("bg-primary"))
      bgPrimary.forEach(element => {
        element.classList.remove("bg-primary")
      })
    }
    //hide the generate report button
    if(this.reportsForm.controls.reportPicker.value != "monthly")
      this.buttonVisible = false;
    //reset the dates
    this.toDate = null;
    this.fromDate = null;
  }


  enableReport() {
    this.buttonVisible = true;
  }

  clearSavedData(){
    this.notification = {};
    this.events = [];
    this.uptime = 0;
    this.yellowEvents = 0;
    this.redEvents = 0;
    this.highestSeverity = null;
    this.peakEventLevel = 0;
    this.durationLoaded = false;
    this.notificationLoaded = false;
    this.eventLoaded = false;
    this.uptimeLoaded = false;
    this.dbLevelLoaded = false;
    this.frequency = null;
  }

  //fetches and populates the data necessary to show the report popup
  generatePopupData() {
    this.isLoading = true;
    this.popupVisible = false;
    if (this.reportsForm.controls.reportPicker.value != "monthly") {

      let sinceDate = new Date(this.fromDate.year, this.fromDate.month-1, this.fromDate.day);
      let untilDate;
      if(this.toDate){
        //add one to day to get the contents of the day
        untilDate = new Date(this.toDate.year, this.toDate.month-1, this.toDate.day+1);
      }else{
        //get the range for the single day selected
        untilDate = new Date(this.fromDate.year, this.fromDate.month-1, this.fromDate.day+1);
      } 
      this.siteDetailsService.getSiteScans(this.selectedSite.id, sinceDate, untilDate).pipe().subscribe(
        data => {
          this.frequency = this.determineScanRange(data.scans);
      });
      // Find event time threshold - 24 hrs in past
      let yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));

      // Gets last 24 hours
      this.userNotificationsService.getBySite(this.selectedSite.id, yesterday, true).pipe().subscribe(
        data => {
          this.durationLoaded = true;
          this.notification['lastDayDuration'] = this.calculateEventDurationLast24Hours(data.events);
          this.checkIfDataLoaded();
        },
        error => {          
          let msg = alertify.error("Error getting notifications");
          msg.delay(Constants.ALERT_TIME);
        });

      this.cerberiService.getUserSites(this.userId, this.userRole).pipe().subscribe(
        data => {
          let iter = 0;
          // Find event time threshold - 24 hrs in past
          let yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
          for (let site of data.cerberi) {
            if (site.id == this.selectedSite.id) {
              //handle no data
              if (!site.most_recent_event) {
                this.notification['mostRecentEvent'] = "No interference available";
              } else {
                let mostRecentDate = new Date(site.most_recent_event);
                if (this.isValidDate(mostRecentDate)) {
                  this.notification['mostRecentEvent'] = mostRecentDate.toUTCString();
                }
              }

              this.notificationLoaded = true;
              this.checkIfDataLoaded();
            } else {
              continue;
            }
          }
        }, error => {          
          let msg = alertify.error("Error getting site data");
          msg.delay(Constants.ALERT_TIME);
        }); 
      
      this.siteDetailsService.getSiteUptime(this.selectedSite.id, sinceDate, untilDate).pipe().subscribe(
        data => {
          this.uptime = data.uptime_percentage.toFixed(2);
          this.uptimeLoaded = true;
          this.checkIfDataLoaded();
        },error => {
          this.uptimeLoaded = true;          
          let msg = alertify.error("Error getting site uptime data.");
          msg.delay(Constants.ALERT_TIME);
        });

      //Get the event data for the given time period.
      this.siteDetailsService.getSiteInterference(this.selectedSite.id, sinceDate, untilDate).pipe().subscribe(
        data => {
          this.events = data.events;
          this.eventLoaded = true;
          let unviewedEvents = 0;
          if(this.events.length >0){
            let eventIdArr = []
            this.highestSeverity = "yellow";
            //reset event counts
            this.redEvents = 0;
            this.yellowEvents = 0;
            //set severity and format the dates.
            this.events.forEach(event => {
              if(event['severity'] == "2"){
                this.highestSeverity = "red";
                this.redEvents++;
              }else{
                this.yellowEvents++;
              }
              if(!event.viewed){
                unviewedEvents++;
              }
                
              eventIdArr.push(event['event_id']);
              //parse a date that arrives in the following format: "2022-12-07T19:14:56.048891"
              let startDateSplit = event.start_dtm.split("T");
              let startTimeSplit = startDateSplit[1].split(":")
              let startDateSplitSub = startDateSplit[0].split("-")
              let startSeconds = startTimeSplit[2].split(".")[0]

              let endDateSplit = event.end_dtm.split("T");
              let endTimeSplit = endDateSplit[1].split(":")
              let endDateSplitSub = endDateSplit[0].split("-")
              let endSeconds = endTimeSplit[2].split(".")[0]
              
              // Note that Date.UTC()'s second parameter is monthIndex, not month!
              let startDate = new Date(Date.UTC(startDateSplitSub[0], startDateSplitSub[1] - 1,startDateSplitSub[2],startTimeSplit[0],startTimeSplit[1], startSeconds))
              let endDate = new Date(Date.UTC(endDateSplitSub[0], endDateSplitSub[1] - 1,endDateSplitSub[2],endTimeSplit[0],endTimeSplit[1], endSeconds))
              event.startDate = startDate.toUTCString();
              event.endDate = endDate.toUTCString();
              event.duration = this.msToTime(Math.abs(endDate.getTime()-startDate.getTime()));
            });
            
            this.notification['unviewedEventCount'] = unviewedEvents;
            this.siteDetailsService.getEventPeakLevel(data.cerberus_id, eventIdArr).pipe().subscribe(
              data => {
                if(data != null){
                  let highest = 0;
                  if(data.peak_lvls_over_low_threshold.length >0){
                    highest = data.peak_lvls_over_low_threshold[0];
                    for(let i = 1; i<data.peak_lvls_over_low_threshold.length;i++){
                      if(data.peak_lvls_over_low_threshold[i] > highest){
                        highest = data.peak_lvls_over_low_threshold[i];
                      }
                    }
                  }
                  //set highest and round to two decimal places
                  this.peakEventLevel = highest.toFixed(2);

                  let peakLevelArr = [];

                  //gather the info we need into a handy to use array
                  for(let i= 0; i< data.event_ids.length; i++){
                    peakLevelArr.push({
                      eventId: data.event_ids[i],
                      peakOverThreshold: data.peak_lvls_over_low_threshold[i]
                    })
                  }
                  //add peak level to each unviewed event
                  this.events.forEach(event => {
                    if(!event.viewed){
                      for(let i=0; i<peakLevelArr.length; i++){
                        if(event.event_id ==  peakLevelArr[i].eventId){
                          event.peakOverThreshold = peakLevelArr[i].peakOverThreshold.toFixed(2);
                          break;
                        }
                      }
                    }                    
                  });
                  
                  this.dbLevelLoaded = true;
                  this.checkIfDataLoaded();
                }
              }, error =>{                
                let msg = alertify.error("Error getting peak data.");
                msg.delay(Constants.ALERT_TIME);
                this.isLoading = false;
              });            
          }else{
            this.dbLevelLoaded = true;
            this.highestSeverity = "blue";            
            this.notification['unviewedEventCount'] = unviewedEvents;
            this.checkIfDataLoaded();
          }
        }, error => {
          let msg = alertify.error("Error getting event data.");
          msg.delay(Constants.ALERT_TIME);
          this.isLoading = false;
        });
    } else {
      this.generateMonthlyPopupData();
    }
  }

  generateMonthlyPopupData(){
    this.siteIter = 0;
    this.cerberiService.getUserSites(this.userId, this.userRole).pipe().subscribe(
      data => {
        this.siteCount = data.cerberi.length;
        //set the sinceDate and untilDate of the first and last days of the selected month
        //technically untilDate is midnight of the first day of the next month, but we must include the whole final day of the month.
        let pickerValue = this.reportsForm.controls.monthPicker.value;
        let sinceDate = new Date(pickerValue.year, pickerValue.month - 1, 1);
        let untilDate = new Date(pickerValue.year, pickerValue.month, 1);    
        let now = new Date();
        //if untilDate is in the future, use now instead.
        if(now < untilDate){
          untilDate = now;
        }
        this.month = sinceDate.getMonth();
        this.year = sinceDate.getFullYear();
        for (let site of data.cerberi) {
          //parse location coordinates and add a comma
          let location = this.parseCoords(site.site_location);
          site.lat = location[0];
          site.lon = location[1];
          //parse into a date
          site.lastInterference = new Date(site.most_recent_event).toUTCString();
          //parse the output into a more usable format
          site.last24Hrs = this.parseLastDayDuration(site.last_day_duration);

          //get site uptime
          this.siteDetailsService.getSiteUptime(site.id, sinceDate, untilDate).pipe().subscribe(
            data => {
              site.uptime = data.uptime_percentage.toFixed(2)+"%";
            },error => {              
              let msg = alertify.error("Error getting site uptime data.");
              msg.delay(Constants.ALERT_TIME);
              site.uptime = "Unknown"
            });

          //get site scans
          this.siteDetailsService.getSiteScans(site.id, sinceDate, untilDate).pipe().subscribe(
            data => {
              site.frequency = this.determineScanRange(data.scans);
          });
          //get the interference data for the given site.
          this.siteDetailsService.getSiteInterference(site.id, sinceDate, untilDate).pipe().subscribe(
            data => {
              site.events = data.events;
              this.eventLoaded = true;
              let unviewedEvents = 0;
              let yellowEvents = 0;
              let redEvents = 0;
              let yellowEventDuration = 0;
              let redEventDuration = 0;
              if(site.events.length >0){
                let eventIdArr = []
                //set severity and format the dates.
                site.events.forEach(event => {
                  if(!event.viewed){
                    unviewedEvents++;
                  }
                  let endTime = new Date(event.end_dtm);
                  let startTime = new Date(event.start_dtm);
                  let diff = endTime.getTime() - startTime.getTime();

                  if(event.severity == "1"){
                    yellowEvents++
                    yellowEventDuration += diff;
                  }else{
                    redEvents++;
                    redEventDuration += diff;
                  }
                    
                  eventIdArr.push(event['event_id']);
    
                  let startDate = new Date(event.start_dtm);
                  let endDate = new Date(event.end_dtm)
                  event.startDate = startDate.toUTCString();
                  event.duration = this.msToTime(Math.abs(endDate.getTime()-startDate.getTime()));
                });
                site.yellowEvents = yellowEvents;
                site.redEvents = redEvents;
                site.yellowEventDuration = this.msToTime(yellowEventDuration);
                site.redEventDuration = this.msToTime(redEventDuration);
                site.unviewedEventCount = unviewedEvents;
                this.siteIter++;
                if(this.siteCount == this.siteIter){
                  this.showPopup();
                }
                //leave out peak level call as it's currently not needed and very expensive to call.
                // this.siteDetailsService.getEventPeakLevel(site.id, eventIdArr).pipe().subscribe(
                //   data => {
                //     if(data != null){
                //       let highest = 0;
                //       if(data.peak_lvls_over_low_threshold.length >0){
                //         highest = data.peak_lvls_over_low_threshold[0];
                //         for(let i = 1; i<data.peak_lvls_over_low_threshold.length;i++){
                //           if(data.peak_lvls_over_low_threshold[i] > highest){
                //             highest = data.peak_lvls_over_low_threshold[i];
                //           }
                //         }
                //       }
                //       //set highest and round to two decimal places
                //       site.peakEventLevel = highest.toFixed(2);
    
                //       let peakLevelArr = [];
    
                //       //gather the info we need into a handy to use array
                //       for(let i= 0; i< data.event_ids.length; i++){
                //         peakLevelArr.push({
                //           eventId: data.event_ids[i],
                //           peakOverThreshold: data.peak_lvls_over_low_threshold[i]
                //         })
                //       }
                //       //add peak level to each unviewed event
                //       site.events.forEach(event => {
                //         if(!event.viewed){
                //           for(let i=0; i<peakLevelArr.length; i++){
                //             if(event.event_id ==  peakLevelArr[i].eventId){
                //               event.peakOverThreshold = peakLevelArr[i].peakOverThreshold.toFixed(2);
                //               break;
                //             }
                //           }
                //         }                    
                //       });
                      // this.siteIter++;
                      // if(this.siteCount == this.siteIter){
                      //   this.showPopup();
                      // }
                //     }
                //   }, error =>{
                //     alertify.error("Error getting peak data.");
                //   });            
              } else {
                site.events = [];
                site.yellowEvents = 0;
                site.redEvents = 0;
                site.unviewedEventCount = 0;
                site.yellowEventDuration = this.msToTime(0);
                site.redEventDuration = this.msToTime(0);
                this.siteIter++;
                if (this.siteCount == this.siteIter) {
                  this.showPopup();
                }
              }
            }, error => {              
              let msg = alertify.error("Error getting event data.");
              msg.delay(Constants.ALERT_TIME);
            });
        }
        this.sites = data.cerberi;
      }, error => {        
        let msg = alertify.error("Error getting site data");
        msg.delay(Constants.ALERT_TIME);
      });
  }

  parseCoords(point){
    let lat;
    let lon;

    let data = point.split("(")[1].split(")")[0];
    let splitData = data.split(" ");
    if(splitData){
      lat = splitData[1];
      lon = splitData[0];
    }

    return [lat, lon];
  }

  showPopup() {
    if(this.reportsForm.controls.reportPicker.value != "monthly"){
      let coords = this.parseCoords(this.selectedSite.site_location);
      let fromDate = new Date(this.fromDate.year, this.fromDate.month-1, this.fromDate.day)
      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      this.interferenceData = {
        fromDate: fromDate.toLocaleDateString('en-US', options),
        location: this.selectedSite.name,
        lat: coords[0],
        lon: coords[1],
        notification: this.notification,
        events: this.events,
        highestSeverity: this.highestSeverity,
        peakEventLevel: this.peakEventLevel,
        yellowEvents: this.yellowEvents,
        redEvents: this.redEvents,
        uptime: this.uptime,
        frequency: this.frequency,
        reportType: this.reportsForm.controls.reportPicker.value
      }
      if (this.toDate) {
        let toDate = new Date(this.toDate.year, this.toDate.month-1, this.toDate.day)
        this.interferenceData.toDate = toDate.toLocaleDateString('en-US', options);
      }
      if(this.reportsForm.controls.reportPicker.value == "single" ){
        const dialogRef = this.dialog.open(SingleReportDialog, {
          width: '90%',
          data: this.interferenceData,
        });
      }else if(this.reportsForm.controls.reportPicker.value == "detailed" || this.reportsForm.controls.reportPicker.value == "overTime"){
        const dialogRef = this.dialog.open(DetailedReportDialog, {
          width: '90%',
          data: this.interferenceData,
        });
      }
    }else{
      this.interferenceData = {
        notification: this.notification,
        sites: this.sites,
        month: this.determineMonth(this.month),
        year: this.year,
        reportType: this.reportsForm.controls.reportPicker.value
      }
    }
    
    if(this.reportsForm.controls.reportPicker.value == "monthly"){
      const dialogRef = this.dialog.open(MonthlyReportDialog, {
        width: '90%',
        data: this.interferenceData
      });
    }   
    this.isLoading = false;
    
    this.clearSavedData(); 
  }

  //determine month
  determineMonth(monthNum){
    switch(monthNum){
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";      
    }
  }

  parseLastDayDuration(rawText){
    if(rawText == "N/A"){
      return "0 hrs, 0 mins, 0 secs";
    }else{
      let hours = rawText.split(":")[0];
      let minutes = rawText.split(":")[1];
      let seconds = rawText.split(":")[2].split('.')[0];
      return hours + " hrs, "+ minutes + " mins, " + seconds + " secs"
    }
  }

  determineScanRange(scanData){
    let scanMin;
    let scanMax = 0;
    if(scanData && scanData.length > 0){
      scanMin = scanData[0].start_freq_mhz;
      scanData.forEach(scan => {
        if(scan.start_freq_mhz < scanMin){
          scanMin = scan.start_freq_mhz;
        }
        if(scan.stop_freq_mhz > scanMax){
          scanMax = scan.stop_freq_mhz
        }
      });
    }else{
      return "No scan data";
    }

    return scanMin + " - " + scanMax + " MHz"
  }

  checkIfDataLoaded(){
    if(!this.popupVisible){
      if(this.durationLoaded && this.notificationLoaded && this.eventLoaded && this.dbLevelLoaded && this.uptimeLoaded){
        this.popupVisible = true;
        this.showPopup();
      }
    }    
  }
  isValidDate(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {  // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onDateRangeSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.buttonVisible = false;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.buttonVisible = true;
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.buttonVisible = false;
    }
  }

  onDateSelection(date: NgbDate) {
    this.fromDate = date;
    this.buttonVisible = true;
    this.toDate = null;
  }

  onMonthSelection($event){
    this.buttonVisible = true;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }  

  msToTime(duration) {
    let hours,minutes,seconds;
    hours = Math.floor(duration/1000/60/60);
    minutes = Math.floor((duration/1000/60/60 - hours)*60);
    seconds = Math.floor(((duration/1000/60/60 - hours)*60 - minutes)*60);
  
    return hours + " hours " + minutes + " minutes and " + seconds + " seconds";
  }

  calculateEventDurationLast24Hours(eventData) {
    let yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
    let totalTime = 0;
    for (let event of eventData) {
      if (event.end_dtm != null) {
        let start_date = new Date(event.start_dtm)
        let end_date = new Date(event.end_dtm);
        let diff = 0;
        //check to see if end date is after our yesterday date
        if(end_date > yesterday){
          //check to see if our start date is also before yesterday. If so, use diff between start and end. If not use diff between end and yesterday          
          if(start_date > yesterday){
            diff = end_date.getTime() - start_date.getTime()
          }else{
            diff = end_date.getTime() - yesterday.getTime();
          }
        }
        totalTime += diff;
      }
    }
    //determine duration as a string
    return(this.msToTime(totalTime));
  }

  public generatePdf(data, inputData) {
    //hide the button from pdf
    data.firstElementChild.lastElementChild.hidden = true;
    data.classList.remove("popupContainer");
    window.scrollTo(0, 0);
    let fileName = this.generateDownloadName(inputData);
    html2canvas(data, { allowTaint: true, scrollY: -window.scrollY, scale: 5.0 }).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height-10;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin*2), canvas_image_width, canvas_image_height- top_left_margin);
      }
      pdf.save(fileName);
    });
    data.classList.add("popupContainer");
    //reshow PDF button
    data.firstElementChild.lastElementChild.hidden = false;
  }

  //Create the download file name based on the selected report and date(s)
  generateDownloadName(inputData){
    let str = "";
    let fromDate = new Date(inputData.fromDate)
    let fromDateStr = fromDate.getMonth()+1 + "-" + fromDate.getDate() + "-" + fromDate.getFullYear()

    let toDate;
    let toDateStr 
    if(inputData.toDate){
      toDate = new Date(inputData.toDate);
      toDateStr = toDate.getMonth()+1 + "-" + toDate.getDate() + "-" + toDate.getFullYear()
    }
    
    if(inputData.reportType == "single"){
      str = inputData.location + "_" + fromDateStr +  "_Single_Report";
    }else if(inputData.reportType == "detailed"){
      str = inputData.location + "_" + fromDateStr +  "_Detailed_Report";
    }else if(inputData.reportType == "overTime"){
      str = inputData.location + "_" + fromDateStr + "_" + toDateStr +  "RoT_Report"  ;    
    }else if(inputData.reportType == "monthly"){
      str = inputData.month+"_Monthly_Report";
    }

    return str;
  }
}

@Component({
  selector: 'singleReport',
  templateUrl: 'singleReport.html',
  styleUrls: ['./reports.component.css']
})
export class SingleReportDialog {
  constructor(
    public dialogRef: MatDialogRef<SingleReportDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private reports: ReportsComponent
  ) { }

  generatePdf(data) {
    this.reports.generatePdf(data, this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef && this.dialogRef.close();
  }
}

@Component({
  selector: 'monthlyReport',
  templateUrl: 'monthlyReport.html',
  styleUrls: ['./reports.component.css']
})
export class MonthlyReportDialog {
  constructor(
    public dialogRef: MatDialogRef<MonthlyReportDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private reports: ReportsComponent
  ) { }

  generatePdf(data) {
    this.reports.generatePdf(data, this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'detailedReport',
  templateUrl: 'detailedReport.html',
  styleUrls: ['./reports.component.css']
})
export class DetailedReportDialog {
  constructor(
    public dialogRef: MatDialogRef<DetailedReportDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private reports: ReportsComponent
  ) { }

  generatePdf(data) {
    this.reports.generatePdf(data, this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}