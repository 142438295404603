import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from '../helpers/constants';
import { AuthenticationService } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // Check if route is restricted by role
            console.log(route.data.roles, currentUser.roleName)
            if (route.data.roles && route.data.roles.indexOf(currentUser.roleName) === -1) {
                return false;
            }
 
            // Authorised so return true
            return true;
        }

        // Not logged in so redirect to login page with the return url
        this.router.navigate([Constants.Urls.LOGIN], { queryParams: { returnUrl: state.url }});
        return false;
    }
}