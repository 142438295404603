import { Component, OnInit } from '@angular/core'

@Component({
    selector: './auto-fail-over',
    templateUrl: './auto-fail-over.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class AutoFailOverComponent implements OnInit {

    ngOnInit() {

    }
}