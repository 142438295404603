import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login.component'
import { CoreModule } from '../../core/core.module';
import { AnimationsModule, AlertModule, MessageDivModule } from 'shared-front-end'
import { ForgotPasswordFormModule } from './forgot-password-form/forgot-password-form.module';
import { SignupFormModule } from './signup-form/signup-form.module';
import { LoginFormModule } from './login-form/login-form.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CoreModule,
    AnimationsModule,
    AlertModule,
    MessageDivModule,
    ForgotPasswordFormModule,
    SignupFormModule,
    LoginFormModule
  ],
  exports: [LoginComponent]
})
export class LoginModule { }

