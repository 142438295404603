import { Component, OnInit } from '@angular/core';

@Component({
    selector: './damsnt-hero',
    templateUrl: './damsnt-hero.component.html'
  })

export class DamsntHeroComponent implements OnInit {

    ngOnInit() {

    }

}