import { Component, OnInit } from '@angular/core'

@Component({
    selector: './socket-status',
    templateUrl: './socket-status.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class SocketStatusComponent implements OnInit {

    ngOnInit() {

    }
}