import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementComponent, ModalContentComponent } from './user-management.component';
import { AgGridModule } from 'ag-grid-angular';
import { CoreModule } from 'src/app/core/core.module';
import { AnimationsModule, TemplateRendererModule, TemplateRendererComponent } from 'shared-front-end';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [UserManagementComponent, ModalContentComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    AgGridModule.withComponents([TemplateRendererComponent]),
    CoreModule,
    AnimationsModule,
    TemplateRendererModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    NgbModule
  ],
  entryComponents: [ModalContentComponent],
})
export class UserManagementModule { }
