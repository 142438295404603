import { Component, OnInit } from '@angular/core'

@Component({
    selector: './achelous-reports',
    templateUrl: './achelous-reports.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class AchelousReportsComponent implements OnInit {

    ngOnInit() {

    }
}