import { of } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Constants } from '../helpers/constants';

export class MockAuthenticationService extends AuthenticationService {
    
    /**
     * Mocks logging in
     * @param email 
     * @param password 
     */
    public login(email: string, password: string) {
        let user = {
            authToken: "ZnJlZEBzbGF0ZVJvY2suY29tfDE1Njc2Mjc4NzIuNDc0NTc4fDY2NTZjNjAzMTI0NzE4NzRjMDQzOGRhOWFjYzNlY2ZjZmI1ODBhZjZhMjYzYWE3MDg2YmVjM2JjMDNiZmU4ZGI=|k1ryKuMNqqmGh95uPhiDRGmsq+97waeRywcn5eKD5up5J90iHanMaNJo8QM7fkK8hVkTlc3nT3OWXSsFAhFm4XgmThPZuL+osKZds1LPSgx+uJ5KdSpj3i359a+u8vqiWBdSrUaCRhJG3vEPQrJBTFwCy8e6xd53fcao/t/7xZDZlIQ/lkFi4mxMTSZyFMrrZnkkIpybDMG6ZRTy5RipM3FBkjBt9kDBJD9uZRALi6/KKSUU2WW3TNMFQ9hKrYl2wGF3QGCbjCoFJ8yZERBsDs077vdOUYpUDGES3NG65gRTLiN9fu/Iw2O3S3PzBIweR7M0TS0xOW5lrlQKjn9lEA==",
            ok: "authorized",
            roleId: 3,
            roleName: "Administrator"
        }

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem(Constants.Logout.STORE_KEY, Date.now().toString())
        
        return of(user);
    }

}