import { first } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserRolesService } from '../services/user-roles.service'

export class MockUserRolesService extends UserRolesService {
    /**
     * Mocks getting all roles from the DB
     */
    getAll() {
        let roles = [{
            description: "Has access to only their assigned sites.",
            id: 1,
            roleName: "User"
        },
        {
            description: "Has access to all sites, can assign users to sites.",
            id: 2,
            roleName: "SitesManager"
        },
        {
            description: "Super User with access to all configurable options in system.",
            id: 3,
            roleName: "Administrator"
        },
        {
            description: "A user that has created an account, but it waiting for admin approval.",
            id: 4,
            roleName: "Pending"
        }]

        return of(roles);
    }
}