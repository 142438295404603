import { Component, OnInit } from '@angular/core'

@Component({
    selector: './antenna-controller',
    templateUrl: './antenna-controller.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class AntennaControllerComponent implements OnInit {

    ngOnInit() {

    }
}