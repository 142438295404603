import { Component, OnInit } from '@angular/core'

@Component({
    selector: './window-menu',
    templateUrl: './window-menu.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class WindowMenuComponent implements OnInit {

    ngOnInit() {

    }
}