import { Component, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CerberiService } from 'src/app/core/services/cerberi.service';
import { Constants, UserNotificationsService, SiteDetailsService, Utility} from 'shared-front-end';
import * as alertify from 'alertifyjs';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomTooltip } from './custom-tooltip.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import * as L from 'leaflet';
import { interval, Subscription } from 'rxjs';
import * as Plotly from 'plotly.js/dist/plotly.js';
import { kill } from 'process';
import "../../../../node_modules/leaflet.browser.print/dist/leaflet.browser.print.min.js"
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.css']
})

export class SiteDetailsComponent implements AfterViewInit {
  notesForm: FormGroup;
  public mapGraph = Constants.SitesMap;
  //public spectrogram;
  //public tracegraph;
  private siteInterferenceData:any = [];
  private initialFirstEventId;
  private siteNotificationData:any = [];
  private cerberus_id;
  private siteLat;
  private siteLon;
  private currentUser;
  private user_id;
  private scan_id;
  private event_id;
  private siteName;
  private eventData;
  private dfData = [];
  private scan_data:any = [];
  private scanMasks: any = [];
  private aiData;
  private windowWidth = 0;
  
  //private slider;
  //private myBubble;
  private trace_start_time;
  private trace_end_time;
  private event_start_time;
  private event_end_time;
  private fileUrl;
  private traceDtm;

  private interferenceCount = 0;
  
  private trace_interval = 1 * 60 * 1000;
  private ms_to_min = 60000;
  private min_slideval = -100;
  private max_slideval = 0;
  private bubble_date;
  private slide_val = 0;
  private value= new Date('September 27, 2021 14:23:00').getTime();
  private value2= new Date('September 27, 2021 14:24:00').getTime();
  private value3= new Date('September 27, 2021 14:25:00').getTime();
  private currentDate;
  private loadedTraceData;
  private onlyDisplayingPartialData = false;
  private span
  private span2
  private span3;
  private radialData;

  //button navigation enable/disable
  private disable_BackButtons = false;  
  private disable_ForwButtons = false;
  private subscription: Subscription;

  private plotTypes = [
    {
      name: "A1-B1 Combo",
      value: "A1-B1 Combo"
    },
    {
      name: "A1 Only",
      value: "A1 Only"
    },
    {
      name: "B1 Only",
      value: "B1 Only"
    }
  ]

  private frameworkComponents = { customTooltip: CustomTooltip };

  private map;
  private layersControl

  private initMap(): void {
    this.map = L.map('map', {
      center: [ this.siteLat, this.siteLon ],
      zoom: 16,
      zoomControl: false,
      doubleClickZoom: false,
      boxZoom: false
    });
    let mbAttr = 'Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP'
    let mbUrl = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
    const tiles = L.tileLayer(mbUrl, {
      maxZoom: 16,
      minZoom:16,
      attribution: mbAttr
    });

    //add compass
    let north = L.control({ position: "topright" });
    north.onAdd = function (map) {
      let div = L.DomUtil.create("div", "info legend");
      div.innerHTML = '<img src="/assets/images/north.png" height="35px" width="35px">';
      return div;
    }
    north.addTo(this.map);

    //add dot to center
    let Icon = L.Icon.extend({
      options: {
        iconSize: [25, 25],
        shadowSize: [0, 0],
        iconAnchor: [14, 20],
        shadowAnchor: [0, 0],
        popupAnchor: [0, 0]
      }
    });
    let dotIcon;
    if(this.siteName.includes("HRIT")){
      dotIcon = new Icon({
        iconUrl:'/assets/images/dotHRIT.png',
        shadowUrl: '/assets/images/dotHRIT.png'
      })
    }else{
      dotIcon = new Icon({
        iconUrl: '/assets/images/dot.png',
        shadowUrl: '/assets/images/dot.png'
      })
    }
    let marker = L.marker([this.siteLat, this.siteLon], { icon: dotIcon }).addTo(this.map);
    marker._icon.classList.remove("leaflet-interactive")

    this.map.dragging.disable();
    tiles.addTo(this.map);
    this.map.invalidateSize();

    //L.control.browserPrint().addTo(this.map)
    L.control.scale().addTo(this.map)
  }
  

  //private num_trace;

  //init for dfGraph
  public dfGraph = {
    style: {
      height: "100%",
      width: "100%"
    },
    data: null,
    config: {
      responsive: true,
    },
    layout : {
      autosize: true,
      polar: {
        radialaxis: {
          visible: false,
          range: [0, 1]          
        },
        bgcolor:'rgba(0,0,0,0)',
        angularaxis:{
          rotation: 90,
          gridcolor: 'rgba(0,0,0,0)'
        }
      },
      margin: {
        r: 0,
        t: 50,
        b: 90,
        l: 0,
        pad: 0
      },
      showlegend: false,
      paper_bgcolor: 'rgba(0,0,0,0)'  
    }
  }

  public getRowStyle  = params => {
    if (!params.node.data.viewed) {        
      return { fontWeight: 'bold' };
    }else
      return undefined;
  };

  // Init for the breach table
  public breachTable = {
    title: 'Breaches',
    pagination: true,
    tooltipShowDelay: 0,
    columnDefs: [
      //{ headerName: 'id', field: 'id', filter: true},
      //{ headerName: 'scan_id', field: 'scan_id', filter: true },
      { headerName: 'Event #', field: 'event_id', filter: true, sortable: true, resizable: true, minWidth: 85},
      { headerName: 'Start Date (GMT)', field: 'start_dtm', filter: true, sortable: true, comparator: this.dateCompare, resizable: true, minWidth: 140},
      { headerName: 'End Date (GMT)', field: 'end_dtm', filter: true, sortable: true, comparator: this.dateCompare, resizable: true, minWidth:140},
      { headerName: 'Start ', field: 'startRFC3339', hide: true},
      { headerName: 'End ', field: 'endRFC339', hide: true},
      { headerName: 'Duration', field: 'duration', filter: true, sortable: true, resizable: true, minWidth:95},
      {
        headerName: 'Severity', tooltipComponent: 'customTooltip', tooltipField: 'severity', field: 'severity', minWidth:100, filter: true, sortable: true, resizable: true,
        cellStyle: function (params) {
          if (params.value == '1') {
            //mark police cells as red
            return { backgroundColor: 'rgba(249, 220, 31, 0.6)' };
          }else if(params.value == '2'){
            return { backgroundColor: 'rgba(230, 39, 57, 0.6)' };
          } else {
            return null;
          }
        }
      },
      // Issue #630 Hide all DAMS-NT content from the UI
      // { headerName: 'DAMSNT', field: 'damsnt', headerTooltip: 'DAMSNT Message Popup', onCellClicked: this.makeCellClicked.bind(this), cellStyle: {color: 'blue'}, resizable: true, minWidth:100},
      { headerName: 'Notes', field: 'notesText', onCellClicked: this.openNotesModal.bind(this), cellStyle: {color: 'blue'}, resizable: true, filter: true, sortable: true, minWidth:90},
      //Issue #602 Hiding these fields for now
      // { headerName: 'Sensor Name', field: 'sensor_name', filter: true, sortable: true, headerTooltip: 'Sensor Name', resizable: true },
      // { headerName: 'Sensor Location', field: 'sensor_location', filter: true, sortable: true, headerTooltip: 'Sensor Location', resizable: true},
      // { headerName: 'Start Freq (MHz)', field: 'start_freq_mhz', filter: true, sortable: true, headerTooltip: 'Start Freq', resizable: true},
      // { headerName: 'Stop Freq (MHz)', field: 'stop_freq_mhz', filter: true, sortable: true, headerTooltip: 'Stop Freq', resizable: true},
      // { headerName: 'Trace Type', field: 'average_type', filter: true, sortable: true, headerTooltip: 'Trace Type', resizable: true},
      // { headerName: 'Detector Type', field: 'detector_function', filter: true, sortable: true, headerTooltip: 'Detector Type', resizable: true},
      // { headerName: 'Yellow Intef Lvl', field: 'low_breach_dbm', filter: true, sortable: true, headerTooltip: 'Yellow Interference Level', resizable: true},
      // { headerName: 'Red Intef Lvl', field: 'high_breach_dbm', filter: true, sortable: true, headerTooltip: 'Red Interference Level', resizable: true},
      // { headerName: 'Display Points', field: 'number_display_points', filter: true, sortable: true, headerTooltip: 'Number Display Points', resizable: true},
      // { headerName: 'Res Bdw MHz', field: 'res_bdw_mhz', filter: true, sortable: true, headerTooltip: 'Res Bdw MHz', resizable: true},
      // { headerName: 'Trailing Buffer Traces', field: 'trailing_buffer_traces', filter: true, sortable: true, headerTooltip: 'Trailing Buffer Traces', resizable: true},
      // { headerName: 'Video BDW MHz', field: 'video_bdw_mhz', filter: true, sortable: true, headerTooltip: 'Video BDW MHz', resizable: true},
      //Don't hide external gain though. It may be useful.
      //{ headerName: 'External Gain (dB)', field: 'db_ext_gain', filter: true, sortable: true, headerTooltip: 'External Gain (dB)', resizable: true},
      { headerName: 'AI Data', field: 'aiDataText', cellStyle: {color: 'blue'}, resizable: true, onCellClicked: this.openAiDataModal.bind(this), minWidth: 80}
      // { headerName: 'Max Rx Power (dBm)', field: 'max_rx_power_dbm', filter: true, sortable: true},
      // { headerName: 'Frequency (MHz)', field: 'max_rx_power_freq_mhz', filter: true, sortable: true}
    ],

    rowData: null,
  };

  //Ensures we can compare the formatted dates.
  private dateCompare(date1, date2){
    let jsDate1 = new Date(date1);
    let jsDate2 = new Date(date2);

    return jsDate1.getTime() - jsDate2.getTime();
  }

  // Init for the breach table
  public notificationTable = {
    title: 'Notifications',
    pagination: true,
    columnDefs: [
      { headerName: 'Start Date (GMT)', field: 'startDtm', filter: true, sortable: true},
      { headerName: 'End Date (GMT)', field: 'endDtm', filter: true, sortable: true},
      { headerName: 'Duration', field: 'duration', filter: true, sortable: true},
      { headerName: 'Severity', field: 'severityStr', filter: true, sortable: true}
    ],

    rowData: null,
  };

  // Init for the spectrogram
  public spectrogram = {
    style: {
      height: "100%",
      width: "100%"
    },
    data: null,
    config: {
      responsive: true,
    },
    layout: {
      title: 'Spectrogram - Received Signal Strength (dBm)',
      xaxis: {
        title: 'Frequency (MHz)'
      },
      yaxis: {
        title: 'Time (GMT)',
        autorange: 'reversed',
        linecolor: 'black',
        linewidth: 2,
        mirror: true,
        tickfont: {
          size: '10'  // date display font on y-axix
        },
        tickangle: 45,
        ticklen: 8,
        tickwidth: 4
      },
      margin: {
        r: 0,
        t: 50,
        b: 65,
        l: 75,
        pad: 0
      },
      // may remove trace label on tooltip, need to confirm
      hoverlabel: {
        namelength: 0
      }
    },
  }



  // Init for the trace graph
  // 4-21-2022 Trace Graph removed at request of RF team.
  // public traceGraph = {
  //   style: {
  //     height: '100%',
  //     width: '100%'
  //   },
  //   data: null,
  //   config: {
  //     responsive: true,
  //   },
  //   layout: {
  //     title: 'Surface Plot - Received Signal Strength (dBm)',
  //     scene: {
  //       xaxis: {
  //         title: 'Frequency (MHz)',
  //         autorange: 'reversed'
  //       },
  //       yaxis: {
  //         title: 'Time (GMT)',
  //       },
  //       zaxis: {
  //         title: 'Received Signal Strength (dBm)'
  //       },
  //     },

  //     margin: {
  //       r: 0,
  //       t: 50,
  //       b: 50,
  //       l: 0,
  //       pad: 0
  //     }
  //   },
  // };

  @ViewChild('numMessages') numMessages: ElementRef;
  @ViewChild('percentGreen') percentGreen: number;
  @ViewChild('percentYellow') percentYellow: number;
  @ViewChild('percentRed') percentRed: number;
  @ViewChild('percentGray') percentGray: number;  
  //@ViewChild('surfacePlotDiv') public surfacePlotDiv: ElementRef;
  @ViewChild('dfGraphDiv') public dfGraphDiv: ElementRef;
  @ViewChild('dateSlider') public dateSlider: ElementRef;
  //@ViewChild('chartSwitcher') public chartSwitcher: MatSlideToggle;
  @ViewChild('polarPlot') public polarPlot: Plotly;
  @ViewChild('spectrogramElement') public spectrogramElement: Plotly;
  @ViewChild('plotPicker') plotPicker;
  constructor(
    private http: HttpClient,
    private cerberiService: CerberiService,
    private userNotificationsService: UserNotificationsService,
    private siteDetailsService: SiteDetailsService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer
  ) { }

  ngOnDestroy(){
    if(this.subscription)
      this.subscription.unsubscribe();
    alertify.dismissAll();
  }

  ngAfterViewInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.user_id = this.currentUser['id']
    let modal = document.getElementById("myModal");
    let notesModal = document.getElementById("notesModal");
    let aiModal = document.getElementById("aiDataModal");
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
      if (event.target == notesModal) {
        notesModal.style.display = "none";
      }
      if (event.target == aiModal) {
        aiModal.style.display = "none";
      }
    }
    //set chart label color to black.
    //this.chartSwitcher._elementRef.nativeElement.children[0].style.color="black";
    // Get the <span> element that closes the modal
    this.span  = document.getElementsByClassName('close')[0] as HTMLElement;
    this.span2 = document.getElementsByClassName('close')[1] as HTMLElement;
    this.span3 = document.getElementsByClassName('close')[2] as HTMLElement;
    this.span.onclick = function() {
      modal.style.display = "none";
    }
    this.span2.onclick = function() {
      notesModal.style.display = "none";
    }
    this.span3.onclick = function() {
      aiModal.style.display = "none";
    }

    // need to access and save cerberus_id so available if page refreshes
    if (history.state.cerb_id != null) {
      this.cerberus_id = history.state.cerb_id;
      this.siteLat = history.state.siteLat;
      this.siteLon = history.state.siteLon;
      this.siteName = history.state.siteName;
      localStorage.setItem("cerberus_id", this.cerberus_id);
      localStorage.setItem("siteLat", this.siteLat);
      localStorage.setItem("siteLon", this.siteLon);
      localStorage.setItem("siteName", this.siteName);
    } else {
      this.cerberus_id = localStorage.getItem("cerberus_id");
      this.siteLat = localStorage.getItem("siteLat");
      this.siteLon = localStorage.getItem("siteLon");
      this.siteName = localStorage.getItem("siteName");
    }

    this.siteDetailsService.getSiteInterference(this.cerberus_id)
    .pipe(first())
    .subscribe(
      data => {
        this.siteInterferenceData = [...data.events];
        if(data.events && data.events.length >0)
          this.initialFirstEventId = data.events[0].event_id;
        //this.siteInterferenceData = this.formatTimeStamps(data.events);
        if (this.siteInterferenceData.length == 0) {
          this.breachTable.rowData = this.siteInterferenceData;
        } else {
          // calculates event duration
          this.calculateEventDuration();
          this.interferenceCount = this.siteInterferenceData.length;
        }
        
        let counter = 0;

        let low, high; 
        let scan_id_arr = [];
        let curScanId = data.events[0].scan_id;
        low = 0;
        for (let i = 0; i < data.events.length; i++) {
          let event = data.events[i]
          if(event.scan_id != curScanId){
            high = i-1;
            let pair = {
              low: low,
              high: high,
              id: curScanId
            }
            scan_id_arr.push(pair);
            low = i;
            curScanId = event.scan_id;
          }

          if(i == data.events.length-1){
            high = i;
            let pair = {
              low: low,
              high: high,
              id: curScanId
            }
            scan_id_arr.push(pair);
          }
        }
        for(let i = 0; i<scan_id_arr.length; i++){
          let scan = scan_id_arr[i];
          this.siteDetailsService.getScanDetails(this.cerberus_id, scan.id)
            .pipe(first())
            .subscribe(
              scanData => {
                for(let i = scan.low; i<=scan.high; i++){
                  //We don't need scan start and end times. They will corrupt the event times if combined. Also remove the unused scan notes.
                  delete scanData.scan_details[0].end_dtm;
                  delete scanData.scan_details[0].start_dtm;
                  delete scanData.scan_details[0].notes;
                  this.siteInterferenceData[i] = Object.assign(data.events[i], scanData.scan_details[0])
                  this.siteInterferenceData[i].damsnt = 'Summary link';
                  this.siteInterferenceData[i].startRFC3339 = this.siteInterferenceData[i].start_dtm
                  this.siteInterferenceData[i].endRFC3339 = this.siteInterferenceData[i].end_dtm
                  this.siteInterferenceData[i].start_dtm = Utility.dateFormat(this.siteInterferenceData[i].start_dtm);
                  this.siteInterferenceData[i].end_dtm = Utility.dateFormat(this.siteInterferenceData[i].end_dtm);
                  if(this.siteInterferenceData[i].notes && this.siteInterferenceData[i].notes.length > 0){
                    this.siteInterferenceData[i].notesText = this.siteInterferenceData[i].notes;
                  }else{
                    this.siteInterferenceData[i].notesText = 'New Note';
                  }          
                  this.siteInterferenceData[i].aiDataText = "AI Data"        
                  counter += 1;
                  // Load trace data
                  if (scanData.length == 0) {                    
                    let msg = alertify.error("No stored scan data for selected event.");
                    msg.delay(Constants.ALERT_TIME);
                  }
  
                  if(counter == this.interferenceCount){
                    //sort the list by most recent first
                    this.siteInterferenceData.sort(function(a, b){
                      return new Date(b.startDtm).getTime() - new Date(a.startDtm).getTime()
                    })
                  }
                }
                this.breachTable.rowData = [...this.siteInterferenceData];
              },
              error => {                
                let msg = alertify.error("Error getting scan data");
                msg.delay(Constants.ALERT_TIME);
              });
        }

        // Load breach data
        this.notificationTable.rowData = this.siteInterferenceData;
        this.onGridReady(this.notificationTable); //this.http.get('/assets/breaches.json');

        // Marks user notifications as viewed
        this.userNotificationsService.markAsViewed(this.user_id, this.cerberus_id)
          .pipe(first())
          .subscribe(
            data => {
              console.log('markViewed:', data)
            },
            error => {
              let msg = alertify.error("Error marking notifications as viewed");
              msg.delay(Constants.ALERT_TIME);
            });
      },
      error => {
        let msg = alertify.error("Error getting interference events");
        msg.delay(Constants.ALERT_TIME);
      });

    //this.slider = document.getElementById("myRange");
    //this.myBubble = document.getElementById("bubbleValue");

    // assign slider properties to varables since used ofter
    //this.min_slideval = this.slider.min ? this.slider.min : -100;
    //this.max_slideval  = this.slider.max ? this.slider.max : 0;

    //this.isForwardValid = false;
     
    // Initialize the current slider value, set the bubble text
    //this.updateBubbleValue(this.max_slideval);

    this.cerberiService.getUserSites(this.user_id, this.currentUser['role'])
    .pipe(first())
    .subscribe(
      data => {
        for(let i = 0; i<data.cerberi.length; i++){
          if(this.cerberus_id == data.cerberi[i].id)
            this.siteName = data.cerberi[i].name;
        }
        
      });

      this.siteDetailsService.getDamsNT(this.cerberus_id)
      .pipe(first())
      .subscribe(
        data => {
          if (data != null) {
            this.eventData = data;
          }
        },
        error => {          
          let msg = alertify.error("Error getting DAMSNT data.");
          msg.delay(Constants.ALERT_TIME);
        });

        //this.surfacePlotDiv.nativeElement.hidden = true;
        
        this.initMap()

        //select the first option of the option list
        let item = this.plotPicker.itemsList.items[0]
        this.plotPicker.select(item);

        //set an interval of 10 seconds
        const source = interval(10000);
        this.subscription = source.subscribe(val=> this.checkEventUpdate());
  }

  ngOnInit(){   
    this.notesForm = this.formBuilder.group({
      notes: ['']
    });
  }

  async fillDFData(dfData){
    this.dfData = [];
    let curAngle = 360;
    
    //determine the angle we have. For example an array of length 72 will result in each value representing a 5 degree cut of the circle
    let cutAngle = 360/dfData.length;
    dfData.forEach(num => {
      let tempData = {
        x: num,
        type: "scatterpolar",
        mode: "lines",
        r: [0, 1, 1, 0],
        theta: [0, curAngle, curAngle+cutAngle, 0],
        fill: "toself",
        fillcolor: 'green',
        name: 'Received Power: ' + num,
        line: {
          color: 'rgba(0, 0, 0, 0.0);'
        }
      };
      //determine color here      
      tempData.fillcolor = this.determineFillColor(num)
      curAngle -= cutAngle;
      this.dfData.push(tempData);
    })
    this.dfGraph.data = this.dfData;

    //ensure that the graph is using the data before drawing the plot. 
    await Utility.delay(100);
    Plotly.toImage(this.polarPlot, {
      format: 'png',
      height: 4000,
      width: 4000
    }).then((url) => {
      this.addPlotToMap(url);
    })
  }

  //Adds the plot image to the map.
  addPlotToMap(url){
    let TWO_MILE_RADIUS_IN_METERS = 3218.69;
    let imageBounds = L.latLng(this.siteLat, this.siteLon).toBounds(TWO_MILE_RADIUS_IN_METERS)
    if(this.layersControl != null)
      this.map.removeLayer(this.layersControl);
   
    this.layersControl = L.imageOverlay(url,imageBounds).addTo(this.map);      
  }

  //switches between showing the surface plot and the DF chart on a map.
  // switchGraphs(){
  //   if(!this.dfGraphDiv.nativeElement.hidden){
  //     this.dfGraphDiv.nativeElement.hidden = true;
  //     this.surfacePlotDiv.nativeElement.hidden = false; 
  //   }else{
  //     this.dfGraphDiv.nativeElement.hidden = false;
  //     this.surfacePlotDiv.nativeElement.hidden = true;
  //     this.map.invalidateSize();
  //    }
  // }

  //Will determine the color for each trace of the DF plot
  //based upon scan high and low breach values. 
  determineFillColor(value){
    let valInDbm = 10 * Math.log10(value);
    
    let opacity = 0.6;
    //If value is above our max of -40dBm, set opacity to 60%
    if(valInDbm > -40){
      return 'rgba(24, 17, 145,' + opacity + ')';
    }
    
    //If value is below our max of -100 dBm, set opacity to 10%
    if(valInDbm < -100){
      return 'rgba(24, 17, 145, .1)';
    }

    //if between -40 and -60 dBm a 1 dBm increase represents a .5% decrease in opacity
    if(valInDbm < -40 && valInDbm >= -60){
      let diff = Math.abs(valInDbm - (-40))
      opacity -= diff * 0.005
    }else { //if lower than -60 dBm a 1 decrease represents a 1% decrease in opacity (capped at -100 dBm above)
      opacity = .5;
      let diff = Math.abs(valInDbm - (-60))
      opacity -= diff * 0.01;
    } 

    return 'rgba(24, 17, 145,' + opacity + ')';
  }

  //Correctly formats the time stamps
  formatTimeStamps(events){
    //the amount of characters we need in our YYYY-MM-DD:HH:MM:SS timestamp
    const MAGIC_NUMBER = 19;
    for(let i=0; i<events.length; i++){
      if(events[i].start_dtm)
        events[i].start_dtm = events[i].start_dtm.substring(0,MAGIC_NUMBER);
      if(events[i].end_dtm)
        events[i].end_dtm = events[i].end_dtm.substring(0,MAGIC_NUMBER);
    }
    return events;
  }

  //Will gather the information for the row in which it was clicked and grab the 
  //DAMS-NT message summary associated with that trace row
  makeCellClicked() {
    let date = new Date(this.event_start_time);
    let datePlusOne = new Date(this.event_end_time);
    //Retrieve the CSV data
    this.siteDetailsService.downloadDamsNT(this.cerberus_id, this.scan_id, this.event_id)
      .pipe(first())
      .subscribe(
        data => {
          if (data != null) {
            const blob = new Blob([data.csv], { type: 'application/octet-stream' });

            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
          }
        },
        error => {          
          let msg = alertify.error("Error getting DAMSNT data.");
          msg.delay(Constants.ALERT_TIME);
        });

        //Populate the summary data
        let summary = null;
        for(let i = 0; i<this.eventData.events.length; i++){
          if(this.eventData.events[i].event_id == this.event_id){
            summary = this.eventData.events[i].damsnt_message_summary;
            break;
          }              
        }
        if(summary.total_count){
          this.numMessages = summary.total_count;
          this.percentGray = Math.round(summary.percent_gray * 100) / 100;
          this.percentGreen = Math.round(summary.percent_green * 100) / 100;
          this.percentRed = Math.round(summary.percent_red * 100) / 100;
          this.percentYellow = Math.round(summary.percent_yellow * 100) / 100;
        }else{
          //handle no messages and set everything to 0.
          summary.total_count = 0;
          summary.percent_gray = 0;
          summary.percent_green = 0;
          summary.percent_red = 0;
          summary.percent_yellow = 0;
          this.numMessages = summary.total_count;
          this.percentGray = summary.percent_gray;
          this.percentGreen = summary.percent_green;
          this.percentRed = summary.percent_red;
          this.percentYellow = summary.percent_yellow;
        }
    let modal = document.getElementById("myModal");
    modal.style.display = "block";
  }

  onNotesSubmit(){
    this.siteDetailsService.getSetNotes(this.cerberus_id,this.event_id, this.notesForm.controls.notes.value)
      .pipe(first())
      .subscribe(
        data => {
          alertify.success("Notes have been updated.");
          let notesModal = document.getElementById("notesModal");
          notesModal.style.display = "none";
          let index = this.findInterferenceArrayIndex(this.event_id);
          if(this.notesForm.controls.notes.value.length > 0){
            this.siteInterferenceData[index].notesText = this.notesForm.controls.notes.value;
          }else
            this.siteInterferenceData[index].notesText = "New Notes";
          
          this.breachTable.rowData = [...this.siteInterferenceData];
        },
        error => {          
          let msg = alertify.error("Error setting notes data.");
          msg.delay(Constants.ALERT_TIME);
        });
  }

  closeNotes(){
    this.notesForm.controls.notes.setValue("");
    let notesModal = document.getElementById("notesModal");
    notesModal.style.display = "none";
  }

  openNotesModal() {
    //Retrieve the CSV data
    this.siteDetailsService.getSetNotes(this.cerberus_id,this.event_id, null)
      .pipe(first())
      .subscribe(
        data => {
          if (data != null) {
            if(data.notes == " ")
              data.notes = "";
            this.notesForm.controls.notes.setValue(data.notes);
            let modal = document.getElementById("notesModal");
            modal.style.display = "block";
          }
        },
        error => {          
          let msg = alertify.error("Error getting notes data.");
          msg.delay(Constants.ALERT_TIME);
        });
  }

  openAiDataModal() {
    //Retrieve the AI data
    this.siteDetailsService.getAiData(this.cerberus_id, this.scan_id, this.trace_start_time, this.trace_end_time)
      .pipe(first())
      .subscribe(
        data => {
          if (data != null) {
            this.aiData = data.ai_details;
            for(let i = 0; i<this.aiData.length; i++){
              if(this.aiData[i].ai_data.length == 0){
                this.aiData[i].ai_data = "No data"
              }
              this.aiData[i].traceTime = new Date(this.aiData[i].trace_dtm).toUTCString();
            }
            let modal = document.getElementById("aiDataModal");
            modal.style.display = "block";
          }
        },
        error => {          
          let msg = alertify.error("Error getting AI data.");
          msg.delay(Constants.ALERT_TIME);
        });
    
    this.siteDetailsService.getSetNotes(this.cerberus_id,this.event_id, null)
      .pipe(first())
      .subscribe(
        data => {
          if (data != null) {
            
            let modal = document.getElementById("aiDataModal");
            modal.style.display = "block";
          }
        },
        error => {          
          let msg = alertify.error("Error getting notes data.");
          msg.delay(Constants.ALERT_TIME);
        });
  }

  // calculate event duration
  calculateEventDuration() {
    for (let event of this.siteInterferenceData) {
      if (event['end_dtm'] != null) {
        let start_date = new Date(event['start_dtm']);
        let end_date = new Date(event['end_dtm']);
        let diff = new Date(end_date.getTime() - start_date.getTime());

        // determine duration as a string
        let days = Math.floor(diff.getTime() / (24 * 60 * this.ms_to_min));
        let hrs  = Math.floor(diff.getTime() / (60 * this.ms_to_min)) - (days * 24);
        let min = Math.floor(diff.getTime() / (this.ms_to_min)) - (hrs * 60);
        let sec = diff.getSeconds()
        let ms = diff.getMilliseconds();

        // build string
        event['duration'] = days + 'd ' + hrs + 'h ' + min + 'm ' + sec + 's '

      }
    }
  }

  // range slider change event - only fires when mouse button is released
  updateTraceGraphs() {

    // need to take the date of this since it's a string once associated with bubble
    this.bubble_date = new Date(this.bubble_date);

    this.trace_start_time = new Date(this.bubble_date.getTime() - this.trace_interval / 2);
    this.trace_end_time = new Date(this.bubble_date.getTime() + this.trace_interval / 2);

    this.getScanAndTraceData(undefined)

  }

  // calculates date dispayed inside bubble and positions it at range slider button
  // Update the current slider value (each time you drag the slider handle)
  updateBubbleValue(val) {
    const newVal = Number(((val - this.min_slideval) * 100) / (this.max_slideval - this.min_slideval));
    // set bubble value with date time
    // get event end - event start in ms
    if(this.event_end_time && this.event_start_time){
      let delta_time = (this.event_end_time.getTime() - this.event_start_time.getTime());
      let norm_time = (val / (this.max_slideval - this.min_slideval)) * delta_time;
      let date_str = new Date(this.event_end_time.getTime() + norm_time);
  
      this.bubble_date = date_str.toUTCString();
  
      // Sorta magic numbers based on size of the native UI thumb
      document.getElementById("bubbleValue").style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    }    
  }

  // < and > button click events
  getNewTraceRecords(str) {
    let time_incr = this.trace_interval;

    if (str == "back") {
      this.trace_end_time = this.trace_start_time
      this.trace_start_time = new Date(this.trace_start_time.getTime() - time_incr)
    } else {
      this.trace_start_time = this.trace_end_time
      this.trace_end_time = new Date(this.trace_end_time.getTime() + time_incr)
    }

    this.getScanAndTraceData(undefined)

  }

  // << and >> button click events
  getExtremeTraceRecords(str) {
    let time_incr = this.trace_interval;

    if (str == "back") {
      this.trace_start_time = this.event_start_time
      this.trace_end_time = new Date(this.event_start_time.getTime() + time_incr)
    } else {
      this.trace_start_time = new Date(this.event_end_time.getTime() - time_incr)
      this.trace_end_time = this.event_end_time
    }

    this.getScanAndTraceData(undefined)

  }

  /* Grid Event Functions */

  // Sets the columns of the grid to fill width
  onGridReady(grid: any) {
    if(grid && grid.api)
      grid.api.sizeColumnsToFit();
  }

  // Sets the first row to selected
  onFirstDataRendered(grid: any) {
    grid.api.sizeColumnsToFit();
    grid.api.getRowNode('0').setSelected(true)
    // needs to be called the first time to load trace data
    this.onGridSelection(grid)
  }

  onFirstDataRenderedNotification(grid: any){
    grid.api.sizeColumnsToFit();
    grid.api.getRowNode('0').setSelected(true);
    // needs to be called the first time to load trace data
    //this.onGridSelectionNotification(grid)
  }

  // because our dates are timestamp without timezone (but essentially UTC),
  // our db time string when converted to a date is referenced to our local time,
  // a GMT-4 is added to the string. Once this is passed to the python, 4 hrs gets
  // added back to the date which we don't want. Therefore all times here in the
  // frontend (that come from the db - interference_event table) need to be converted to local time.
  // I suppose I could just pass the date around as a string, not sure that would
  // work when I need to subtract 15min from the db time and convert back to a date string
  utcDateFromDBtoLocalDate(utcDateString) {
    // check utcDateString for null since that will default to 1969, which we don't want
    if (utcDateString != null) {
      let tempDate = new Date(utcDateString);
      return new Date(tempDate.getTime() + tempDate.getTimezoneOffset() * 60000)
    } else {
      return new Date();
    }
  }

  // Renders the graphs with the selected row data - grid rowClicked event
  // onGridSelection is called for rowClicked, if called for rowSelected
  // it executes twice, once for old row deselect and once for the new selection
  onGridSelection(grid: any) {
    this.scan_id = grid.api.getSelectedRows()[0]['scan_id'];
    this.event_id = grid.api.getSelectedRows()[0]['event_id'];
    this.event_start_time = new Date(grid.api.getSelectedRows()[0]['startRFC3339']);
    this.event_end_time = new Date(grid.api.getSelectedRows()[0]['endRFC3339']);
    this.traceDtm = new Date(grid.api.getSelectedRows()[0]['traceDtm']);
    
    let delta_time = (this.event_end_time.getTime() - this.event_start_time.getTime());

    if (delta_time > this.trace_interval) {
      this.trace_start_time = new Date(this.event_end_time.getTime() - this.trace_interval)
      this.trace_end_time = this.event_end_time
    } else {
      this.trace_start_time = this.event_start_time
      this.trace_end_time = this.event_end_time
    }
    this.getScanAndTraceData(grid);

    //select the first option of the option list to make sure the label is correct
    let item = this.plotPicker.itemsList.items[0]
    this.plotPicker.select(item);
  }

  onNotificationSelection(grid:any){
    let startTime = grid.api.getSelectedRows()[0].creation_time;
    let interferenceArray = [];
    for(let i = 0; i<this.siteInterferenceData.length; i++){
      if(new Date(this.siteInterferenceData[i].startDtm).getTime() == startTime.getTime()){
        interferenceArray.push(this.siteInterferenceData[i]);
      }
    }
    //this.breachTable.rowData = interferenceArray;
  }

  getScanAndTraceData(grid) {
    let trace_data:any = [];

    let id;
    if(grid){
      id = grid.api.getSelectedRows()[0]['event_id'];
      for(let i = 0; i<this.siteInterferenceData.length; i++){
        if(id == this.siteInterferenceData[i].event_id){
          this.trace_start_time = this.siteInterferenceData[i].startRFC3339;
          this.trace_end_time = this.siteInterferenceData[i].endRFC3339;
        }
      }
    }    

    if (this.trace_start_time < this.event_start_time) {
      this.trace_start_time = this.event_start_time;
    }

    if (this.trace_end_time > this.event_end_time) {
      this.trace_end_time = this.event_end_time;
    }

    this.siteDetailsService.getScanDetails(this.cerberus_id, this.scan_id)
      .pipe(first())
      .subscribe(
        data => {
          this.scan_data = data.scan_details;
          this.scanMasks = data.scan_masks;
          let emptyArr = [0] 
          this.siteDetailsService.getRadialData(this.cerberus_id, id).pipe(first())
            .subscribe(
              data => {
                this.radialData = data;
                if(data.data.length > 0){
                  this.aggregateDFData(data);
                  this.fillDFData(this.radialData.A1B1MaxArr);
                }else{
                  //clear the DF plot if there's no data
                  this.fillDFData(emptyArr) 
                  let msg = alertify.warning("No radial data available.");
                  msg.delay(Constants.ALERT_TIME);  
                }
              }, error => {
                //clear the DF plot if there's an error
                this.fillDFData(emptyArr)       
                let msg = alertify.error("Error getting radial data.");
                msg.delay(Constants.ALERT_TIME);

              });
          // Load trace data
          if (this.scan_data.length == 0) {            
            let msg = alertify.error("No stored scan data for selected event.");
            msg.delay(Constants.ALERT_TIME);
          }
        },
        error => {          
          let msg = alertify.error("Error getting scan data");
          msg.delay(Constants.ALERT_TIME);
        });
    
    //get the width of the spectrogram svg
    if(this.windowWidth == 0){
      this.windowWidth = this.float2int(document.getElementsByClassName("gridlayer")[0].getBoundingClientRect().width);
    } 
    this.siteDetailsService.getTraceDetails(this.cerberus_id, this.scan_id, this.windowWidth, this.trace_start_time, this.trace_end_time)
      .pipe(first())
      .subscribe(
        data => {
          if(data.trace_details){
            this.onlyDisplayingPartialData = false;
            trace_data = data.trace_details;
            let MAX_TRACE_ROWS = 40;
            //If the trace data is exceptionally long, then only load the first MAX_TRACE_ROWS rows. We will present the option to load all of it with a warning to the user.
            if(trace_data.length>MAX_TRACE_ROWS){
              this.loadedTraceData = trace_data;
              this.onlyDisplayingPartialData = true;
              trace_data = trace_data.slice(0, MAX_TRACE_ROWS);
            }
            // Load trace data
            if (trace_data != null) {
              this.handleTraceData(trace_data, this.scan_data)
            } else {              
              let msg = alertify.error("No stored trace data for selected times.");
              msg.delay(Constants.ALERT_TIME);
            }
          }
        },
        error => {
          let msg = alertify.error("Error getting trace data");
          msg.delay(Constants.ALERT_TIME);
        });


      // let trace_center_time = (this.trace_end_time.getTime() - this.trace_start_time.getTime())/2;
      // let trace_date_ms = this.event_end_time.getTime() - (this.trace_end_time.getTime() - trace_center_time); 
      // let delta_time = (this.event_end_time.getTime() - this.event_start_time.getTime());

      // this.slide_val = - ((trace_date_ms) / delta_time) * (this.max_slideval - this.min_slideval);

      // this.updateBubbleValue(this.slide_val);

      // this.controlButtonState();
  }

  /*
    Casts a floating point number to an int
  */
  float2int(value){
    return value | 0;
  }

  /*
    Takes in the dfData and will produce aggregated max arrays of A1, B1, and then the combination max A1B1
    Adds these arrays to the member variable 'radialData' for use when the plot type picker is changed.
  */
  aggregateDFData(dfData){
    if(dfData.data && dfData.data.length>0){
      let traceCount = dfData.data.length;
      let bearingCount = dfData.data[0]['A1 Max'].length;
      let A1MaxArr = [];
      let B1MaxArr = [];
      let A1B1MaxArr = [];
      for(let i = 0; i<bearingCount; i++){
        //compare each value in the trace
        let tempA1 = [];
        let tempB1 = [];
        for(let j = 0; j<traceCount; j++){
          tempA1.push(dfData.data[j]['A1 Max'][i])
          tempB1.push(dfData.data[j]['B1 Max'][i])
        }
        A1MaxArr.push(Math.max(...tempA1));
        B1MaxArr.push(Math.max(...tempB1));
        A1B1MaxArr.push(Math.max(A1MaxArr[i], B1MaxArr[i]))
      }
      this.radialData.A1MaxArr = A1MaxArr;
      this.radialData.B1MaxArr = B1MaxArr;
      this.radialData.A1B1MaxArr = A1B1MaxArr;
    }
  }

  //Will load the proper array based on picker value
  //0: A1B1
  //1: A1
  //2: B1
  plotPickerChanged(selectValue){
    if(this.radialData){
      if(selectValue == this.plotTypes[0]){
        this.fillDFData(this.radialData.A1B1MaxArr);
      }else if(selectValue == this.plotTypes[1]){
        this.fillDFData(this.radialData.A1MaxArr);
      }else if(selectValue == this.plotTypes[2]){
        this.fillDFData(this.radialData.B1MaxArr);
      }
    }    
  }

  //will ask the user if they're sure they want to show all of the data and inform them of the performance issues
  confirmShowData(){
    let self = this;
    alertify.confirm('Are you sure you want to load all of the data?', 'Showing all of the data will cause performance issues and greatly slow the responsiveness of this page. Note: Up to the first 1000 rows of trace data will be loaded.',
    // If they confirm, delete the user
    async function () {
      self.handleTraceData(self.loadedTraceData, self.scan_data);
      self.onlyDisplayingPartialData = false;
    },
    // Do nothing if Cancel is clicked
    function () {

    });
  }
  

  controlButtonState() {
    // control whether buttons are enabled or disabled
    // use separate if else, if pulling entire event both can be true
    if (this.trace_start_time == this.event_start_time) {
      this.disable_BackButtons = true;
    } else {
      this.disable_BackButtons = false;
    }

    if (this.trace_end_time == this.event_end_time) {
      this.disable_ForwButtons = true;
    } else {
      this.disable_ForwButtons = false;
    }
  }

  // Parses the observable into arrays and stores arrays into graph objects
  handleTraceData(trace_data: any, scan_data: any) {
    let timeData: string [] = [];
    let frequencyData: number[] = [];
    let amplitudeData: number[][] = [];
    let amplitudeDataSpectrogram: number[][] = [];
    let timeDataTraceGraph: string [] = [];
    
    // a word regarding these 2D arrays.  They're used for the plot hovertext, and need to match
    // the z amplitude structure despite the AI data not having a unique value for each trace frequency
    // To remedy this the AI data for each trace is mapped to every frequency.  Now for some unknown
    // reason, the hover index for the spectrogram and 3D surface plots are reversed, where z is (37)(501)
    // for the spectrogram, the 3D surface needs (501)(37), essentially aiData transposed.
    let aiData:string[][] = [];  // needs to match same structure as amplitude
    let aiDataTranspose:string[][] = [];  // needs to match same structure as amplitude, but reversed - dont know why

    [timeData, frequencyData, amplitudeData, aiData] = this.parseTraceData(trace_data, scan_data);

    // the 3D surface plot hovertemplate needs to match the z data, however the indices are reversed ??WHY??
    if(aiData && aiData.length>0)
      aiDataTranspose = this.transpose(aiData);

    let amplitudeMin: number;
    let amplitudeMax: number;

    [amplitudeMin, amplitudeMax] = this.getArrayMinMax(amplitudeData)
    for(let i = 0; i<amplitudeData.length; i++){
      amplitudeDataSpectrogram.push(this.checkMaskData(frequencyData, amplitudeData[i], scan_data));
    }

    // Sample interference levels with scan breach parameters
    const interferenceLevel1: number = scan_data[0]['low_breach_dbm'];
    const interferenceLevel2: number = scan_data[0]['high_breach_dbm'];
    const refLevel: number  = scan_data[0]['reference_level_dbm']

    // Set graph colorscale
    let colorscale = this.makeColorscale(refLevel - 100, refLevel, interferenceLevel1, interferenceLevel2)

    //if we only have a duration of 1 second, we need to add another row to the data to enable display on the trace graph
    if(timeData.length == 1){
      let temp = new Date(timeData[0]);
      temp.setSeconds(temp.getSeconds() -1);
      let hours= temp.getHours().toString();
      let minutes = temp.getMinutes().toString();
      let seconds = temp.getSeconds().toString();
      timeDataTraceGraph.push(hours+":"+minutes+":"+seconds);
    }
    //format the time data
    for (let item of timeData) {
      let mytime = item.substr(item.indexOf('T') + 1, 8);
      timeDataTraceGraph.push(mytime);
    }

    // don't know why, but I need to reset this
    this.spectrogram.layout.yaxis.autorange = 'reversed';

    // Store data to graph objects
    this.spectrogram.data = [{
      x: frequencyData,
      y: timeData,
      z: amplitudeDataSpectrogram,
      customdata: aiData,  // this seems crazy that I can't concatenate a string, but need an array with same structure as z data
                           // <extra></extra> gets rid of trace label
      hovertemplate: "Time (GMT): %{y}<br>" +
                     "Frequency: %{x: .1f} MHz<br>" +
                     //"RSL: %{text: .1f} dBm<br>" +
                     "AI Data: %{customdata}<extra></extra>",
      text: amplitudeData,
      zauto: false,
      zmin: refLevel - 100,
      zmax: refLevel,
      type: 'heatmap',
      colorscale: colorscale,      
      showscale: false,
    }]

    //if we only have a duration of 1 second, we need to add another row to the data to enable display on the trace graph
    if(amplitudeData.length == 1){
      amplitudeData.push(amplitudeData[0]);
    }

    // this.traceGraph.data = [{
    //   x: frequencyData,
    //   // will need to determine min/max and partition into intervals
    //   y: timeDataTraceGraph,
    //   z: amplitudeData,
    //   customdata: aiDataTranspose,  // this seems crazy that I can't concatenate a string, but needs an array
    //                                 // with same structure as z data, <extra></extra> gets rid of trace label
    //   hovertemplate: "Time (GMT): %{y}<br>" +
    //                  "Frequency: %{x: .1f} MHz<br>" +
    //                  "RSL: %{z: .1f} dBm<br>" +
    //                  "AI Data: %{customdata}<extra></extra>",
    //   zauto: false,
    //   zmin: refLevel - 100,
    //   zmax: refLevel,
    //   type: 'surface',
    //   colorscale: colorscale,
    // }]
    let update = {
      width: "80%"
    }
    //Plotly.relayout('surfacePlotGraph', update);
  }

  //if a point on the heatmap lies within the range of a mask AND breaches a mask threshold
  //change that point to the appropriate threshold level. This will ensure that the point is colored properly
  checkMaskData(amplitudeData, traceData, scanData){
    let scan = scanData[0]
    let redThreshold = scan.high_breach_dbm;
    let yellowThreshold = scan.low_breach_dbm;
    let masks = this.scanMasks;
    let adjustedData = JSON.parse(JSON.stringify(traceData));;
    for(let i =0; i<adjustedData.length; i++){
      masks.forEach(mask => {
        if(this.inRange(amplitudeData[i], mask.start_freq_mhz, mask.stop_freq_mhz)){
          if(adjustedData[i]>mask.high_breach_dbm){
            //set to red
            adjustedData[i] = redThreshold;
          }else if(adjustedData[i] > mask.low_breach_dbm){
            //set to yellow
            adjustedData[i] = yellowThreshold;
          }
          if(adjustedData[i] < mask.high_breach_dbm && adjustedData[i] > mask.low_breach_dbm){
            //set to yellow
            adjustedData[i] = yellowThreshold;
          }
          if(adjustedData[i] < mask.low_breach_dbm && adjustedData[i] > yellowThreshold){
            //set to blue
            adjustedData[i] = yellowThreshold-1;
          }
        }
      });
    }
    return adjustedData;
  }

  // return true if in range, otherwise false
  inRange(x, min, max) {
    return ((x - min) * (x - max) <= 0);
  }

  parseTraceData(trace_data: any, scan_data: any) {
    let timeData: Date [] = [];
    let frequencyData = [];
    let amplitudeData: number[][] = [];
    let amplitudeRow: number[] = [];
    let aiData: string[][] = [];  // needs to match same structure as amplitude

    // get number of ampplitude points
    let num_points = scan_data[0]['number_display_points'];
    let start_freq:number = scan_data[0]['start_freq_mhz'];
    let stop_freq:number = scan_data[0]['stop_freq_mhz'];
    let freq_step:number = (stop_freq - start_freq)/(num_points - 1);

    // get the frequencies that correspond to each of the num_points amplitudes
    for (let i = 0; i < num_points; i++) {
      frequencyData.push(start_freq + (i * freq_step));
    }

    //use squeezed data instead
    frequencyData = trace_data[0].squeezed_freqs;
    
    let row_aidata = new Array(trace_data[0]['squeezed_data'].length);

    for (let timeRow in trace_data) {

      // in order to include in the graph tooltip, ai_data needs to match the same structure
      // as amplitude, a two dimensional array.  To do that we need to fill every value in
      // a row (each frequency) (with the same ai data
      if (trace_data[timeRow]['ai_data'] != null && trace_data[timeRow]['ai_data'] != ""){
        //parse the ai data into a JSON
        if (this.tryParseJSONObject(trace_data[timeRow]['ai_data'])){
          let rowData = JSON.parse(trace_data[timeRow]['ai_data']);
          //check for 'error' key from AI service
          if (rowData.error) {
            row_aidata.fill(rowData.error)
              //fill ai data for the hover
              aiData.push(row_aidata);
          } else if (rowData.label == null){
            //check for null label
              row_aidata.fill("no data")
              aiData.push(row_aidata)
          } else {
            //convert the prediction value from string and into a more understandable 
            let prediction = parseFloat(rowData.prediction);
            prediction = prediction * 100;
            //limit to 2 decimal places
            let predictionStr = prediction.toFixed(2).toString();
            let strArr = []
            strArr.push(rowData.label + " Prediction:" + predictionStr + "%");
            row_aidata.fill(strArr);
            //fill ai data for the hover
            aiData.push(row_aidata);          
          }
        } else {
          row_aidata.fill("no data")
          aiData.push(row_aidata)
        }
      }
     
      timeData.push(trace_data[timeRow]['trace_dtm']);
      // amplitudeRow = trace_data[timeRow]['data'];
      amplitudeRow = trace_data[timeRow]['squeezed_data'];
      amplitudeData.push(amplitudeRow);

    }

    return [timeData, frequencyData, amplitudeData, aiData];
  }

  //checks if a string is valid JSON
  tryParseJSONObject (jsonString){
    try {
      var o = JSON.parse(jsonString);
      
      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object", 
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === "object") {
          return o;
      }
    }
    catch (e) { }
    console.log('AI data is not JSON string')
    return false;
  }

  transpose(myarray) {
    return myarray[0].map((col, c) => myarray.map((row, r) => myarray[r][c]));
  }


  // Gets the min and max of an array
  getArrayMinMax(array: number[][]) {
    let checkValues: boolean = false;
    let min: number;
    let max: number;

    // Loop through rows in array
    for (let xRow in array) {
      // Get max and min of row
      let rowMin = Math.min(...array[xRow])
      let rowMax = Math.max(...array[xRow])
      // Compare max and min of row to max and min of previous rows
      if (checkValues) {
        if (rowMin < min) {
          min = rowMin;
        }
        if (rowMax > max) {
          max = rowMax;
        }
      } else {
        min = rowMin;
        max = rowMax;
        checkValues = true;
      }
    }
    return [min, max]
  }
  
  makeColorscale(scaleMin, scaleMax, interferenceLevel1, interferenceLevel2) {
    let percentBreach1 = (interferenceLevel1 - scaleMin) / (scaleMax - scaleMin);
    let percentBreach2 = (interferenceLevel2 - scaleMin) / (scaleMax - scaleMin);

    // Default colorscale.
    let colorscale = [
      [0, 'green'],
      [1, 'green']
    ]


    // Check for proper threshold configuration.
    if (percentBreach1 > 0.0 && percentBreach1 < 1.0 && percentBreach2 > 0.0 && percentBreach2 < 1.0) {
      // Both thresholds are within the visible plot.
      colorscale = [
        [0, 'green'],
        [percentBreach1 - .00001, 'green'],
        [percentBreach1, 'rgb(249, 220, 31)'],
        [percentBreach2, 'rgb(230, 39, 57)'],
        [percentBreach2 + .00001, 'rgb(230, 39, 57)'],
        [1, 'rgb(230, 39, 57)']
      ];
    } 
    else {
      // Something is wrong, but we'll give them the benefit of the doubt, and
      // return a colorscale.
      if (percentBreach1 > 0.0 && percentBreach1 <= 1.0 && percentBreach2 > 1.0) {
        // Only threshold1 is within the visible plot.
        colorscale = [
          [0, 'green'],
          [percentBreach1 - .00001, 'green'],
          [percentBreach1, 'rgb(249, 220, 31)'],
          [1, 'rgb(230, 39, 57)']
        ];
      }
      else if (percentBreach1 < 0.0 && percentBreach2 >= 0.0 && percentBreach2 < 1.0) {
        // Only threshold2 is within the visible plot.
        colorscale = [
          [0, 'rgb(249, 220, 31)'],
          [percentBreach2, 'rgb(230, 39, 57)'],
          [percentBreach2 + .00001, 'rgb(230, 39, 57)'],
          [1, 'rgb(230, 39, 57)']
        ];
      }
      else if (percentBreach1 < 0.0 && percentBreach2 < 0.0) {
        // Both thresholds are below the visible plot.
        colorscale = [
          [0, 'rgb(230, 39, 57)'],
          [1, 'rgb(230, 39, 57)']
        ];
      }
      else if (percentBreach1 > 1.0 && percentBreach2 > 1.0) {
        // Both thresholds are above the visible plot.
        colorscale = [
          [0, 'green'],
          [1, 'green']
        ];
      }
      else if (percentBreach1 == 0.0 && percentBreach2 > 0.0 && percentBreach2 < 1.0) {
        // Threshold1 is the lower boundary, and threshold2 is within the visible plot.
        colorscale = [
          [0, 'rgb(249, 220, 31)'],
          [percentBreach2, 'rgb(230, 39, 57)'],
          [percentBreach2 + .00001, 'rgb(230, 39, 57)'],
          [1, 'rgb(230, 39, 57)']
        ];
      }
      else if (percentBreach2 == 1.0 && percentBreach1 > 0.0 && percentBreach1 < 1.0) {
        // Threshold2 is the upper boundary, and threshold1 is within the visible plot.
        colorscale = [
          [0, 'green'],
          [percentBreach1 - .00001, 'green'],
          [percentBreach1, 'rgb(249, 220, 31)'],
          [1, 'rgb(230, 39, 57)'],
        ];
      }
      else if (percentBreach1 <= 0.0 && percentBreach2 >= 1.0) {
        // Catch the rest of the outlying conditions.
        colorscale = [
          [0, 'rgb(249, 220, 31)'],
          [1, 'rgb(230, 39, 57)']
        ];
      }
    }

    return colorscale;
  }

  //will return the index for the siteInterferenceData array matching event_id
  findInterferenceArrayIndex(eventId){
    for(let i=0; i<this.siteInterferenceData.length; i++){
      if(this.siteInterferenceData[i].event_id == eventId){
        return i;
      }
    }
  }

  checkEventUpdate(){
    this.siteDetailsService.getSiteInterference(this.cerberus_id)
    .pipe(first())
    .subscribe(
      data => {
        if(data.events.length > 0 && data.events[0].event_id != this.initialFirstEventId){
          let msg = alertify.warning("There are new events! Refresh page to see them.");
          //show message for a long time.
          msg.delay(10000000000000);
          this.subscription.unsubscribe();
        }
      })
  }
}
