import { Observable } from 'rxjs';
import {switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'

import { TopicService } from '../topic/topic.service';
import { Topic } from '../topic/topic';

@Component({
    selector: './help-topic-list',
    templateUrl: './help-topic-list.component.html',
    styleUrls: ['./help-topic-list.css']
})

export class HelpTopicListComponent implements OnInit {

    topics$!: Observable<Topic[]>;
    selectedId = 0;

    constructor(
        private service: TopicService,
        private route: ActivatedRoute
    ) { }


    ngOnInit() {
        this.topics$ = this.route.paramMap.pipe(
            switchMap(params => {
                this.selectedId = parseInt(params.get('id')!, 1);
                return this.service.getTopics();
            })
        )
    }
}