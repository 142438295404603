import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelpers, Message, UserService, Constants } from 'shared-front-end';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.css', '../login-page-forms.css']
})
export class ForgotPasswordFormComponent implements OnInit {
  @Output() recoverySubmit = new EventEmitter<string>();

  forgotPasswordForm: FormGroup;
  submitted = false;
  loading = false;

  message = new Message();

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
    // Forgot password form validators
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  /**
   * Convenience getter for easy access to forgot password form fields
  */
  get forgotPasswordFormFields() { return this.forgotPasswordForm.controls; }

  /**
  * Runs on submit of forgot password form
  */
  onForgotPasswordSubmit() {
    this.submitted = true;
    // Stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    // Authenticate user
    this.userService.recoverPassword(this.forgotPasswordFormFields.email.value)
      .pipe(first())
      .subscribe(
        data => {
          this.handleData(data)
          this.loading = false;
        },
        error => {
          this.message.setMessage(Constants.MessageType.ERROR, error)
          this.loading = false;
        });
  }

  /**
 * Handles logic for response
 * @param data Json response returned from API
 */
  handleData(data) {
    FormHelpers.resetForm(this.forgotPasswordForm, []);
    this.message.setMessage(null, null);
    this.recoverySubmit.emit();
    this.loading = false;
  }

}
