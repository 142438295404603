import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';
import { Constants } from '../helpers/constants';

@Injectable({ providedIn: 'root' })
export class UserRolesService {

    private apiUrl: any;
    public roleList:any

    constructor(
        private http: HttpClient,
        @Inject('API_URL') apiUrl,
    ) {
        this.apiUrl = apiUrl
        this.getRoles();
    }

    /**
     * Gets all roles from the DB
     */
    getAll() {
        return this.http.post<any>(this.apiUrl + Constants.Urls.GET_ROLES, {})
            .pipe(map(data => {
                return data;
            },
            error => {
                return "Error getting user roles"
              }));
    }

    /**
     * Gets and stores all roles to the this.roleList
     */
    getRoles() {
        this.getAll()
          .pipe(first())
          .subscribe(
            data => {
              this.roleList = data as any[]
            },
            error => {
              return "error"
            });
    }

    /**
     * Gets the id of a rolename
     * @param roleList List of role objects
     * @param roleName The name of the role you want the id of
     */
    getRoleId(roleName) {
        let roleId:number;

        this.roleList.user_roles.forEach(role => {
            if (role.roleName==roleName){
                roleId = role.id
            }
        });

        return roleId;
    }

    /**
     * Gets the name of the role
     * @param roleId Role id of the desired role name
     */
    getRoleName(roleId){
        let roleName: string;

        this.roleList.user_roles.forEach(role => {
            if (role.id == roleId){
                roleName = role.roleName
            }
        })

        return roleName;
    }
}