import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CerberiService } from 'src/app/core/services/cerberi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService, Message, Constants } from 'shared-front-end';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css', '../login-page-forms.css']
})
export class LoginFormComponent implements OnInit {

  // Broadcasts change in visiblePane to parent component, when emitted.
  @Output() displayForgotPasswordEvent = new EventEmitter<string>();

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  message = new Message();

  constructor(
    private cerberiService: CerberiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    // Redirect to map page if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {

    // showUsgBanner(){
      let self = this;
      alertify.confirm('RESTRICTED ACCESS', 
      'You are accessing a U.S. Government (USG) Information System (IS) that is ' +
      'provided for USG-authorized use only. By using this IS (which includes any ' +
      'device attached to this IS), you consent to the following conditions:<br />' +
      '-The USG routinely intercepts and monitors communications on this IS for ' +
      'purposes including, but not limited to, penetration testing, Communications ' +
      'Security (COMSEC) monitoring, network operations and defense, personnel ' +
      'misconduct (PM), law enforcement (LE), and counterintelligence (CI) ' +
      'investigations.<br />' +
      '-At any time, the USG may inspect and seize data stored on this IS.<br />' +
      '-Communications using, or data stored on, this IS are not private, are subject ' +
      'to routine monitoring, interception, and search, and may be disclosed or used ' +
      'for any USG-authorized purpose.<br />' +
      '-This IS includes security measures (e.g., authentication and access controls) ' +
      'to protect USG interests--not for your personal benefit or privacy.<br />' +
      '-Notwithstanding the above, using this IS does not constitute consent to PM, LE ' +
      'or CI investigative searching or monitoring of the content of privileged ' +
      'communications, or work product, related to personal representation or services ' +
      'by attorneys, psychotherapists, or clergy, and their assistants. Such ' +
      'communications and work product are private and confidential. ', 
      function(){ alertify.success('Client IP logged') }, function(){ alertify.error('Consent is mandatory'); throw('cancel')});
    

    // Adds form validators
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  /**
   * Convenience getter for easy access to login form fields
  */
  get loginFormFields() { return this.loginForm.controls; }

  /**
   * Runs on submit of login form, authenticates user
   */
  onLoginSubmit() {
    this.submitted = true;

    // Stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    // Authenticate user
    this.authenticationService.login(this.loginFormFields.email.value, this.loginFormFields.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.handleData(data)
          this.loading = false;
        },
        error => {
          this.message.setMessage(Constants.MessageType.ERROR, error)
          this.loading = false;
        });
  }

  /**
   * Tell parent component that the center pane should be displayed
   */
  onForgotPasswordClick() {
    this.displayForgotPasswordEvent.emit();
  }

  /**
   * Handles logic for response
   * @param data Json response returned from API
   */
  handleData(data) {
    if (data.ok) {
      //check if the user only has 1 cerburus site. If so, route to the site-details.
      this.cerberiService.getUserSites(data.user_id, data.user_role)
      .pipe(first())
      .subscribe(
        data => {
          //if only 1 site and we aren't supposed to go somewhere special, go to the site details
          if(data && data.cerberi && data.cerberi.length==1 && this.returnUrl == "/"){
            this.returnUrl += "site-details";
          }
          this.router.navigateByUrl(this.returnUrl);
        },
        error => {    
          this.message.setMessage(Constants.MessageType.ERROR, error);
          localStorage.removeItem('currentUser');
        });
    } else {
      this.message.setMessage(Constants.MessageType.ERROR, Constants.UiMessages.AUTHENTICATION_FAILURE);

    }
  }
}
