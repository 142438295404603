import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteDetailsComponent } from './site-details.component';
import { PlotlyModule } from 'angular-plotly.js';
import { AgGridModule } from 'ag-grid-angular';
import { CoreModule } from '../../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomTooltip } from './custom-tooltip.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [SiteDetailsComponent, CustomTooltip],
  imports: [
    CommonModule,
    PlotlyModule,
    AgGridModule.withComponents([CustomTooltip]),
    CoreModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    NgSelectModule
  ],
  exports: [CustomTooltip]
})
export class SiteDetailsModule { }
