import { Component, OnInit, Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { confirmPasswordValidator, UserService, Message, FormHelpers, Constants, User } from 'shared-front-end'
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css', '../login-page-forms.css']
})
export class SignupFormComponent implements OnInit {
  
  @Output() registrationSubmit = new EventEmitter<string>();

  public readonly siteKey = '6LdRyBEnAAAAAOHgreuR0WatTZh9T0F8gmyl-WDW';

  message = new Message();
  signupForm: FormGroup;
  submitted = false;
  loading = false;
  captchaIsLoaded = false;
  captchaSuccess = false;
  captchaIsExpired = false;
  captchaResponse?: string;
  
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // Signup form validators
    this.signupForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(16)]],
      confirmPassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      supervisor: ['', Validators.required],
      region: ['', Validators.required],
      requestedSites: ['', Validators.required],
      recaptcha: ['', Validators.required]
    }, { validators: confirmPasswordValidator });
  }

  // Convenience getter for easy access to form fields
  get signupFormFields() { return this.signupForm.controls; }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  /**
   * Handles signup form submit
   */
  onSignupSubmit() {
    this.submitted = true;
    
    // Stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    }

    this.loading = true;
    
    let user = new User(this.signupForm.value)
    this.userService.registerUser(user)
      .pipe(first())
      .subscribe(
        data => {
          this.handleResponse(data)
        },
        error => {
          this.handleResponse(error);
        });    
  }

  /**
   * Handles display of results of account creation
  */ 
  handleResponse(data){
    if (data == "Conflict"){
        this.message.setMessage(Constants.MessageType.ERROR, Constants.UiMessages.EMAIL_IN_USE); 
    }else if(!data.error){
      FormHelpers.resetForm(this.signupForm, []);
      this.message.setMessage(null, null);
      this.registrationSubmit.emit();
    }
    this.loading = false;
  }
}
