/* Angular Imports */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

/* Shared Component imports */
import { JwtInterceptor, ErrorInterceptor } from 'shared-front-end';

/* Font awesome */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faAngleLeft, faUserEdit, faTrashAlt, faExclamation, faCalendarTimes, faHistory, faPlusSquare, faThermometerHalf, faTint, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

/* Page Component Imports */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* Page Imports */
import { LoginModule } from './modules/login/login.module';
import { SiteLocationMapModule } from './modules/site-location-map/site-location-map.module';
import { SiteDetailsModule } from './modules/site-details/site-details.module';
import { environment } from 'src/environments/environment';
import { UserManagementModule } from './modules/user-management/user-management.module';
import { SiteDashboardModule } from './modules/site-dashboard/site-dashboard.module';
import { HelpModule } from './modules/help/help.module';
import { ReportsModule } from './modules/reports/reports.module';
import { SingleReportDialog } from './modules/reports/reports.component';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    LoginModule,
    SiteLocationMapModule,
    SiteDetailsModule,
    NgbModule,
    UserManagementModule,
    SiteDashboardModule,
    HelpModule,
    ReportsModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: 'API_URL', useFactory: getAPIUrl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
      // Add an icon to the library for convenient access in other components
      library.add(faAngleLeft);
      library.add(faAngleRight);
      library.add(faUserEdit);
      library.add(faTrashAlt);
      library.add(faExclamation);
      library.add(faCalendarTimes)
      library.add(faHistory)
      library.add(faPlusSquare)
      library.add(faThermometerHalf)
      library.add(faTint)
      library.add(faMapMarkerAlt)
    }
}

export function getAPIUrl() {
  return (environment.apiUrl!="") ? environment.apiUrl : "/api"
}
