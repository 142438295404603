import { FormGroup, AbstractControl } from "@angular/forms";
import { Utility } from './utility'

// Jquery
declare var $;

export class FormHelpers {

    static resetForm(formGroup: FormGroup, excludeFields, defaults?) {
        let resetValues = {}
        let control: AbstractControl = null;
        Object.keys(formGroup.controls).forEach((key) => {
            if (excludeFields.indexOf(key) > -1) {
                resetValues[key] = formGroup.get(key).value;
            }

            control = formGroup.controls[key];
            control.setErrors(null);
        });
        formGroup.reset(defaults);
        formGroup.markAsUntouched();
        formGroup.patchValue(resetValues)
    }

    /**
   * Needed whenever a value is set on an input or a new input is created
   */
    static async refreshLabels() {
        await Utility.delay(100)
        $('.floating-label .custom-select, .floating-label .form-control').floatinglabel();
    }
}
