import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models';
import { Constants } from '../helpers/constants';
import { Router } from '@angular/router';
import { UserRolesService } from './user-roles.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    private apiUrl: any;
    public currentUser: Observable<User>;
    public active: boolean

    constructor(
        @Inject('API_URL') apiUrl,
        private http: HttpClient,
        private router: Router,
    ) {
        this.apiUrl = apiUrl;

        // User stored in local storage
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        // Automatic logout functions
        this.check();
        this._initListener();
        this._initInterval();
        localStorage.setItem(Constants.Logout.STORE_KEY, Date.now().toString());
    }

    public getLastAction() {
        return parseInt(localStorage.getItem(Constants.Logout.STORE_KEY));
    }
    public setLastAction(lastAction: number) {
        localStorage.setItem(Constants.Logout.STORE_KEY, lastAction.toString());
    }
    public getActive() {
        return this.active;
    }
    public setActive(active: boolean) {
        this.active = active;
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public login(email: string, password: string) {
        let jsonInfo = {
            "email": email,
            "password": password
        }
        return this.http.post<any>(this.apiUrl + Constants.Urls.LOGIN, jsonInfo)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.authToken) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem(Constants.Logout.STORE_KEY, Date.now().toString())
                    this.currentUserSubject.next(user);
                    this.active = true;
                }
                return user;
            }));
    }

    public logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate([Constants.Urls.LOGIN]);
        this.active = false;
    }

    /**
     * Sets the last action to now
     */
    public setActionToNow() {
        this.setLastAction(Date.now())
    }

    /**
     * Adds event listeners to page to listen for activity
     */
    private _initListener() {
        document.body.addEventListener('click', () => this.reset());
        document.body.addEventListener('mouseover', () => this.reset());
        document.body.addEventListener('mouseout', () => this.reset());
        document.body.addEventListener('keydown', () => this.reset());
        document.body.addEventListener('keyup', () => this.reset());
        document.body.addEventListener('keypress', () => this.reset());
    }

    /**
     * Sets the last action to now
     */
    public reset() {
        this.setLastAction(Date.now());
    }

    /**
     * Sets the activity checker
     */
    private _initInterval() {
        setInterval(() => {
            this.check();
        }, Constants.Logout.CHECK_INTERVAL);
    }

    /**
     * Checks to see if the user is inactive
     */
    public check() {
        //check if active is undefined. If so, get active from localstorage. If it's not there, add it.
        if(this.active == undefined){
            let user = JSON.parse(localStorage.getItem('currentUser'));
            if(user){
                if(user.active){
                    this.active = user.active;
                }else{
                    //if we have an auth token, set user active to true.
                    if(user.authToken.length > 0){
                        user.active = true;
                        this.active = true;
                        localStorage.setItem('currentUser', JSON.stringify(user));
                    }                
                }
            }            
        }
        if (this.active) {
            const now = Date.now();
            const timeleft = this.getLastAction() + Constants.Logout.MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
            const diff = timeleft - now;
            const isTimeout = diff < 0;

            if (isTimeout) {
                this.logout();
            }
        }
    }

}