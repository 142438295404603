import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[slideAnimation]'
})
export class SlideAnimationDirective {
  @Input()
  slideAnimation;

  constructor() { }

  @HostBinding('@slide')
  get slide() {
    return { value: this.slideAnimation };
  }
}
