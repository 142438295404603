import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { SiteLocationMapComponent } from './modules/site-location-map/site-location-map.component';
import { SiteDetailsComponent } from './modules/site-details/site-details.component';
import { SiteDashboardComponent } from './modules/site-dashboard/site-dashboard.component';
import { ReportsComponent } from './modules/reports/reports.component';

import { AuthGuard, Role } from 'shared-front-end';
import { UserManagementComponent } from './modules/user-management/user-management.component';
import { HelpComponent } from './modules/help/help.component';
import { ObjectiveComponent } from './modules/help/mainContent/topic/topics/introduction/objective/objective.component';
import { DRGSComponent } from './modules/help/mainContent/topic/topics/introduction/drgs/drgs.component';
import { WaterManagementComponent } from './modules/help/mainContent/topic/topics/introduction/water-management/water-management.component';
import { GoesDCSInterferenceConcernsComponent } from './modules/help/mainContent/topic/topics/introduction/interferenceConcerns/goesdcs-interference-concerns.component';
import { AntennaControllerComponent } from './modules/help/mainContent/topic/topics/damsnt-drgs/antennaController/antenna-controller';
import { DAMSNTOverviewComponent } from './modules/help/mainContent/topic/topics/damsnt-drgs/systemOverview/damsnt-overview';
import { ParabolicDishComponent } from './modules/help/mainContent/topic/topics/damsnt-drgs/parabolicDish/parabolic-dish';
import { EthernetFiberSystemComponent } from './modules/help/mainContent/topic/topics/damsnt-drgs/ethernetFiberSystem/ethernet-fiber-system';
import { RFFiberComponent } from './modules/help/mainContent/topic/topics/damsnt-drgs/RFFiberModule/rf-fiber';
import { CleaningMaintenanceComponent } from './modules/help/mainContent/topic/topics/damsnt-drgs/cleaningMaintenance/cleaning-maintenance';
import { DRGSEquipmentComponent } from './modules/help/mainContent/topic/topics/damsnt-drgs/drgsEquipment/drgs-equipment';
import { AutoFailOverComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/autofailOver/auto-fail-over.component';
import { CageStatusComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/cageStatusPage/cage-status.component';
import { DataAcquisitionMonitoringComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/dcsDataAcquisition/data-acquisition-monitoring.component';
import { IIMPilotSetupComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/iimPilotSetup/iim-pilot-setup.component';
import { MoveCopySwapComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/moveCopySwap/move-copy-swap.component';
import { PrimeOperationsComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/primeOperations/prime-operations.component';
import { SetupMenuPreferencesComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/setupMenuPreferences/setup-menu-preferences.component';
import { SlotMenuComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/slotMenu/slot-menu.component';
import { SlotStatusPageComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/slotStatusPage/slot-status-page.component';
import { SocketStatusComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/socketStatusPage/socket-status.component';
import { WindowMenuComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/windowMenu/window-menu.component';
import { ViewMenuComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/viewMenu/view-menu.component';
import { DRGSSiteDetailsComponent } from './modules/help/mainContent/topic/topics/achelous/site-details/drgs-site-details.component';
import { AchelousDescriptionComponent } from './modules/help/mainContent/topic/topics/achelous/description/achelous-description.component';
import { AchelousOverviewComponent } from './modules/help/mainContent/topic/topics/achelous/overview/achelous-overview.component';
import { AchelousVideosComponent } from './modules/help/mainContent/topic/topics/videos/achelous/achelous-videos.component';
import { DAMSNTVideosComponent } from './modules/help/mainContent/topic/topics/videos/damsnt/damsnt-videos.component';
import { DRGSVideosComponent } from './modules/help/mainContent/topic/topics/videos/drgs/drgs-videos.component';
import { AlarmCodesComponent } from './modules/help/mainContent/topic/topics/troubleshooting/alarm-codes/alarm-codes.component';
import { CageSystemSetupComponent } from './modules/help/mainContent/topic/topics/damsntsoftware/cageSystemSetup/cage-system-setup.component';
import { DamsNTFAQComponent } from './modules/help/mainContent/topic/topics/troubleshooting/damsnt-faq/damsnt-faq.component';
import { AntennaControllerFAQComponent } from './modules/help/mainContent/topic/topics/troubleshooting/antenna-controller-faq/antenna-controller-faq.component';
import { DRGSSateliteFAQComponent } from './modules/help/mainContent/topic/topics/troubleshooting/drgs-satelite-faq/drgs-satelite-faq.component';
import { SpectrumMonitoringFAQComponent } from './modules/help/mainContent/topic/topics/troubleshooting/spectrum-monitoring-faq/spectrum-monitoring-faq.component';
import { SpecificationsComponent } from './modules/help/mainContent/topic/topics/specifications-warranties/specifications/specifications.component';
import { WarrantiesComponent } from './modules/help/mainContent/topic/topics/specifications-warranties/warranties/warranties.component';
import { AchelousReportsComponent } from './modules/help/mainContent/topic/topics/achelous/reports/achelous-reports.component';
import { AchelousUsersComponent } from './modules/help/mainContent/topic/topics/achelous/users/achelous-users.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: '', component: SiteDashboardComponent, canActivate: [AuthGuard]},
  { path: 'site-details', component: SiteDetailsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.SuperUser, Role.SiteManager, Role.User, Role.RestrictedUser ]}},
  { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.SuperUser, Role.User, Role.SiteManager, Role.RestrictedUser]}},
  // { path: 'site-dashboard', component: SiteDashboardComponent},
  { path: 'site-map', component: SiteLocationMapComponent, canActivate: [AuthGuard]},
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard]},
  { path: 'help', component: HelpComponent, canActivate: [AuthGuard], children: [
    {path:'', component: ObjectiveComponent},
    {path: 'objective', component: ObjectiveComponent},
    {path: 'water-management', component: WaterManagementComponent},
    {path: 'drgs', component: DRGSComponent},
    {path: 'interference-concerns', component: GoesDCSInterferenceConcernsComponent},
    {path: 'damsnt-overview', component: DAMSNTOverviewComponent},
    {path: 'parabolic-dish', component: ParabolicDishComponent},
    {path: 'antenna-controller', component: AntennaControllerComponent},
    {path: 'ethernet-fiber-system', component: EthernetFiberSystemComponent},
    {path: 'rf-fiber', component: RFFiberComponent},
    {path: 'cleaning-maintenance', component: CleaningMaintenanceComponent},
    {path: 'drgs-equipment', component: DRGSEquipmentComponent},
    {path: 'data-acquisition-monitoring', component: DataAcquisitionMonitoringComponent},
    {path: 'slot-status', component: SlotStatusPageComponent},
    {path: 'cage-status', component: CageStatusComponent},
    {path: 'socket-status', component: SocketStatusComponent},
    {path: 'setup-menu-preferences', component: SetupMenuPreferencesComponent},
    {path: 'iim-pilot-setup', component: IIMPilotSetupComponent},
    {path: 'cage-system-setup', component: CageSystemSetupComponent},
    {path: 'prime-operations', component: PrimeOperationsComponent},
    {path: 'move-copy-swap', component: MoveCopySwapComponent},
    {path: 'auto-fail-over', component: AutoFailOverComponent},
    {path: 'view-menu', component: ViewMenuComponent},
    {path: 'window-menu', component: WindowMenuComponent},
    {path: 'slot-menu', component: SlotMenuComponent},
    {path: 'alarm-codes', component: AlarmCodesComponent},
    {path: 'damsnt-faq', component: DamsNTFAQComponent},
    {path: 'antenna-controller-faq', component: AntennaControllerFAQComponent},
    {path: 'achelous-description', component: AchelousDescriptionComponent},
    {path: 'achelous-overview', component: AchelousOverviewComponent},
    {path: 'drgs-videos', component: DRGSVideosComponent},
    {path: 'damsnt-videos', component: DAMSNTVideosComponent},
    {path: 'achelous-videos', component: AchelousVideosComponent},
    {path: 'drgs-satelite-faq', component: DRGSSateliteFAQComponent},
    {path: 'spectrum-monitoring-faq', component: SpectrumMonitoringFAQComponent},
    {path: 'specifications', component: SpecificationsComponent},
    {path: 'warranties', component: WarrantiesComponent},
    {path: 'achelous-reports', component: AchelousReportsComponent},
    {path: 'drgs-site-details', component: DRGSSiteDetailsComponent},
    {path: 'achelous-users', component: AchelousUsersComponent}
  ]},
  
  
  // else go home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
