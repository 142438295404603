import { Component, OnInit } from '@angular/core'

@Component({
    selector: './damsnt-videos',
    templateUrl: './damsnt-videos.component.html',
    styleUrls: ['../../../../../help.component.css']
})

export class DAMSNTVideosComponent implements OnInit {

    ngOnInit() {

    }
}